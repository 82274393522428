import {Button, Col, Form, Row} from "antd";
import {l} from "../../library/locale";
import React from "react";
import {FormItemWrapper} from "../Form/FormItemWrapper";
import {LabelSwitch} from "../Form/Field/LabelSwitch";
import {DateRangeField, MenuField, TagsField, TextField} from "../Form/Field/FormFields";
import {useTranslation} from "react-i18next";

export const CollapseFilter = ({form, onFinish, filterFields}) => {
    const {t} = useTranslation();

    const FilterFieldsResult = []

    const filterItemWrapper = (fieldName, fieldItem, className = '', width = 12) => {
        return (
            <Col key={'filter-' + fieldName} xs={24} sm={width} lg={width}>
                <FormItemWrapper className={className}>
                    {fieldItem}
                </FormItemWrapper>
            </Col>
        )
    }

    let rowKey = 0
    for (const filterFieldsRow of filterFields) {
        let filterRowFields = []

        for (const fieldObj of filterFieldsRow) {
            const fieldSection = fieldObj.section ?? 'page_header.filters'
            let fieldItem = '';
            let fieldWrapperClass = '';

            if (fieldObj.type === 'date-range') {
                fieldWrapperClass = 'field-combined'
                fieldItem = <DateRangeField t={t} section={fieldSection} name={fieldObj.name}/>

            } else if (fieldObj.type === 'text') {
                fieldItem = <TextField t={t} section={fieldSection} {...fieldObj} />
            } else if (fieldObj.type === 'tags') {
                fieldItem = <TagsField t={t} form={form} section={fieldSection} {...fieldObj} />
            } else if (fieldObj.type === 'menu') {
                fieldItem = <MenuField t={t} section={fieldSection} {...fieldObj} />
            } else if (fieldObj.type === 'switcher') {
                fieldItem = (
                    <Form.Item
                        name={fieldObj.name}
                        label=''
                        valuePropName="checked"
                        className="allow-overflow"
                    >
                        <LabelSwitch
                            label={l(fieldSection + '.form.' + fieldObj.name + '.label')}
                        />
                    </Form.Item>
                )
            }

            filterRowFields.push(filterItemWrapper(fieldObj.name, fieldItem, fieldWrapperClass, fieldObj.width ?? 12))

            if (fieldObj.width === 24) {
                FilterFieldsResult.push(<Row key={'filter-row-' + rowKey}>{filterRowFields}</Row>)
                filterRowFields = []
                rowKey++
            }
        }

        const rowClass = rowKey ? 'margin-top-ps' : ''
        FilterFieldsResult.push(<Row key={'filter-row-' + rowKey} className={rowClass}>{filterRowFields}</Row>)
        rowKey++
    }

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                name="settings"
            >

                {FilterFieldsResult}

                <Row className="margin-top-ps">
                    <Form.Item className="float-right">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            {l('common.action.apply')}
                        </Button>
                        <Button
                            type="text"
                            onClick={() => {
                                form.resetFields();
                                onFinish()
                            }}
                        >
                            {l('common.action.cleanup')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    )
}
