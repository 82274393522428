import React, {useState, useEffect, useLayoutEffect} from 'react'
import classes from "./TemplateStore.module.scss"
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Col, InputNumber, Layout, Row} from "antd";
import {ListHeader} from "../../components/List/Header/ListHeader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {StoreCard} from "../../components/Card/Store/StoreCard";
import {SearchBox} from "../../components/Search/SearchBox";
import {createMarkup, deepGet, getLabelForNumeric, inArray, roundPrice, ucFirst} from "../../library/functions";
import {Banner} from "../../components/Banner/Banner";
import {dotenv, routes} from "../../config/config";
import {SimpleModal} from "../../components/Modal/SimpleModal";
import {useTranslation} from "react-i18next";
import Preloader from "../System/Preloader";
import {setTemplateStatus} from "../../redux/reducers/TemplateReducer";
import {AdminBalance} from "../Admin/AdminBalance";
import {notice} from "../../library/notice";
import {reachGoal} from "../../library/metrics";

const {Content} = Layout;

const TemplateStore = () => {
    const section = 'template'
    const localeSection = 'template.store'
    const default_ordering = '-created_at';

    // init hook functions
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // data from URL params
    const params = useParams()
    const project_id = Number(params.project_id)
    const alias = params.alias

    // store and state
    const {admin, project, template, userField} = useSelector(store => store)
    const [ordering, setOrdering] = useState('default')
    const [periodCount, setPeriodCount] = useState(1)
    const [isVisibleModal, setVisibleModal] = useState(false)
    const [isVisibleConfirm, setVisibleConfirm] = useState(false)
    const [filters, setFilters] = useState({})

    // get fields values
    const balance = deepGet(userField, 'balance.value', 0)
    const coins = deepGet(userField, 'coins.value', 0)

    // init universal vars
    const project_item = project.item
    const list = template.store
    const item = template.item

    useLayoutEffect(() => {
        dispatch(setTemplateStatus('loading'));
    }, [])

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            // console.log('admin.authorized', admin.authorized)
            const order = (ordering === 'default') ? default_ordering : ordering // TODO: finish on backend
            let filters_result = {is_public: true, ordering: order}
            if (filters) filters_result = {...filters_result, ...filters}
            dispatch({type: 'getTemplateStoreList', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, ordering, section])

    // get data from API first
    useEffect(() => {
        if (admin.authorized) {
            if (alias) {
                setVisibleModal(true)
                dispatch({type: 'get' + ucFirst(section) + 'OpenItem', admin, alias})
            } else setVisibleModal(false)
        }
    }, [admin.authorized, alias])

    useEffect(() => {
        if (template.status === 'installed' && item.id) {
            dispatch(setTemplateStatus('default'));
            notice.success(t('template.notice.installed'));
            navigate(`${routes.project_list}/${item.project_id}/${section}/data/${item.id}`)
        }
        //eslint-disable-next-line
    }, [template.status])

    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const backToList = () => {
        navigate(`${routes.project_list}/${project_item.id}/store`);
    }

    const onInstall = () => {
        // catch metrics goal
        reachGoal('TemplateInstall', {
            order_price: item.price,
            template_id: item.id,
            project_id: project_id
        })

        // console.log('onInstall', `${routes.project_list}/${project_id}/${section}/install/${item.id}`);
        dispatch({
            type: 'installTemplate', admin,
            data: {project_id: project_id, template_id: item.id, count: periodCount}
        })
    }

    const openItemModal = (alias, e) => {
        e.preventDefault()
        navigate(`${routes.project_list}/${project_item.id}/store/${alias}`);
    }

    const showConfirm = () => {
        setVisibleModal(false);
        setVisibleConfirm(true);
    }

    const cancelConfirm = () => {
        setVisibleConfirm(false);
        setVisibleModal(true);
    }

    // console.log('item.subscription.is_on', deepGet(item, 'params.subscription.is_on'))

    let order_price = item.price * periodCount;
    const notEnoughMoney = !project_item || (item.price && project_item.balance < order_price);

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Banner image="universe"/>
                <ListHeader
                    section={localeSection}
                    onSearch={searchHandler}
                    // onPrimaryWithFolder={recordCreate}
                    // filters={filters}
                    // setFilters={setFilters}
                    // filterFields={filterFields}
                />
                <Content className={`page-container ${classes.container_wrapper}`}>
                    <SearchBox
                        className=""
                        wrapperClasses="visible-xs full-width margin-bottom-sm"
                        key="header-search-button"
                        searchHandler={searchHandler}
                        showCloseButton={false}
                        showInputDefault={true}
                    />

                    {template.status === 'loading' ? <Preloader/> : <div
                        className={`flex flex-row flex-wrap gap-sm ${classes.container}`}
                    >
                        {list.map((record, index) => {
                            let item = {...record}
                            item.photo_url = deepGet(item, 'params.cover.url', item.photo_url)
                            // const demoUrl = deepGet(item, 'params.demo.url')
                            // const templateUrl = `${routes.project_list}/${project_item.id}/store/${item.alias}`
                            const templateUrl = `/${section}/${item.alias}`
                            return <a key={section + item.id} href={templateUrl} onClick={(e) => {
                                openItemModal(item.alias, e)
                            }}>
                                <StoreCard
                                    section={section}
                                    item={item}
                                    // url={templateUrl}
                                    price={record.price}
                                    params={record.params}
                                    active={record.alias === alias}
                                />
                            </a>
                        })}
                    </div>}


                    {item.id ? <>
                        <SimpleModal
                            title={item.title}
                            isVisible={isVisibleModal}
                            setVisible={setVisibleModal}
                            actionLabel="install"
                            onOk={item.price ? showConfirm : onInstall}
                            onCancel={backToList}
                            loadingOnOk={!item.price}
                            leftFooterText={inArray(item.created_by, dotenv.main_admins) ? null : deepGet(item, 'author.name')}
                            disabled={notEnoughMoney}
                            width={980}
                        >
                            {(alias && item.alias === alias) ?
                                <div dangerouslySetInnerHTML={createMarkup(item.product_info)}/> :
                                <Preloader/>
                            }

                            {item.price ? <>
                                <hr className="margin-top-pm"/>
                                <Row gutter={30}>
                                    <Col xs={24} md={12}>
                                        <div className="margin-top-pm font-size-md">
                                            {deepGet(item, 'params.subscription.is_on') && deepGet(item, 'params.subscription.period') ?
                                                <div>
                                                    <div>
                                                        {t('template.item.period')}: {item.params.subscription.period} {
                                                        getLabelForNumeric(item.params.subscription.period, [
                                                            t('template.item.days.0'),
                                                            t('template.item.days.1'),
                                                            t('template.item.days.2')
                                                        ])
                                                    }
                                                    </div>
                                                    <div className="margin-top-xs">
                                                        <span className="margin-right-ps">{t('template.item.count')}:</span>
                                                        <InputNumber
                                                            min={1}
                                                            step={1}
                                                            size="small"
                                                            className="inline always-show-arrows"
                                                            placeholder='1'
                                                            defaultValue={1}
                                                            onChange={setPeriodCount}
                                                            style={{width: 60}}
                                                        />
                                                    </div>
                                                </div> : null}
                                            <div className="margin-top-xs">
                                                {
                                                    (periodCount === 1) ? t('template.item.price') : t('template.item.install_price')
                                                }: {roundPrice(order_price)} {t('common.money.symbol')}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <AdminBalance className="margin-top-ps" projectId={project_item.id}/>
                                    </Col>
                                </Row>
                            </> : null}

                        </SimpleModal>

                        <SimpleModal
                            title={t('template.install.confirm.title')}
                            isVisible={isVisibleConfirm}
                            setVisible={setVisibleConfirm}
                            actionLabel="approve"
                            onOk={onInstall}
                            onCancel={cancelConfirm}
                            loadingOnOk={true}
                            disabled={notEnoughMoney}
                        >
                            <div dangerouslySetInnerHTML={createMarkup(
                                t('template.install.confirm.desc', {
                                    postProcess: 'sprintf', sprintf: [
                                        roundPrice(order_price),
                                        t('common.money.symbol'),
                                        roundPrice(project_item.balance - order_price),
                                        t('common.money.symbol')
                                    ]
                                })
                            )}/>
                        </SimpleModal>
                    </> : null}
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default TemplateStore


// actions={[
//     demoUrl ? <Button
//         className="float-left"
//         key="demo"
//         type="primary"
//     > Демо </Button> : <Button
//         className="float-left"
//         key="more"
//         type="primary"
//     >Подробнее</Button>,
//
//     demoUrl ? <Button
//         className="float-left"
//         key="more"
//         type="default"
//     >Подробнее</Button> : null,
//
//     demoUrl ? <Button
//         className="float-right"
//         key="install"
//         type="default"
//         icon={<PlusOutlined/>}
//     /> : <Button
//         className="float-right"
//         key="install"
//         type="default"
//         icon={<PlusOutlined/>}
//     > Добавить </Button>
// ]}