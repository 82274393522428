import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Divider, Tooltip, Typography} from 'antd';
import {
    DeleteOutlined,
    DollarOutlined,
    EyeOutlined,
    MailOutlined,
    ShoppingOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {routes} from "../../../config/config";
import {redirect, roundPrice, ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import ListTable from "../../../components/List/Table/ListTable";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import {copyToClipboard} from "../../../library/clipboard";
import {SorterWrapper} from "../../../components/List/Table/SorterWrapper";
import {setUtmStoreParam} from "../../../redux/reducers/UtmReducer";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export const UtmListTable = (
    {
        admin,
        project,
        section,
        list,
        filters,
        rowsTotal,
        currentPage = 1,
        pageSize = 10,
    }
) => {
    const Section = ucFirst(section)
    const {t} = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // get state
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isCheckboxButtons, showCheckboxButtons] = useState(false)

    // navigate
    const recordEdit = (record) => navigate(`${routes.project_list}/${project.id}/${section}/edit/${record.id}`)
    const recordOpen = (record) => recordEdit(record)
    // saga
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const navToExternal = (sect, record, external = false) => {
        let link = `${routes.project_list}/${project.id}/${sect}?`
        link += `sources=${record.source}&mediums=${record.medium}&campaigns=${record.campaign}&contents=${record.content}&terms=${record.term}&groups=${record.group}`

        if (external) redirect(link, '_blank')
        else navigate(link)
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar',
            title: <SorterWrapper>&nbsp;&nbsp;{t('common.table.header.id')}</SorterWrapper>,
            width: 70,
            sorter: true,
            responsive: ['sm'],
            render: (value, record) => <TableAvatar icon="user-tag" title={value}/>,
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            title: <SorterWrapper>{t(section + '.list.header.tags')}</SorterWrapper>,
            sorter: true,
            render: (title, record) => (<>
                <Text className="block">{title}</Text>
                <Text type="secondary" className="block font-size-sm visible-xs">
                    <span className="margin-right-ps"><EyeOutlined/> {record.hits}</span>
                    <span className="margin-right-ps"><UserOutlined/> {record.users}</span>
                    <span className="margin-right-ps"><MailOutlined/> {record.leads}</span>
                    <span className="margin-right-ps"><ShoppingOutlined/> {record.orders}</span>
                    <span><DollarOutlined/> {record.income}</span>
                </Text>
            </>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },

        {
            dataIndex: 'income',
            className: 'cursor-pointer text-secondary align-center',
            title: t(section + '.list.header.income.title'),
            responsive: ['sm'],
            width: 100,
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.income, t('common.result.copied.neuter'))
                };
            },
        },

        {
            dataIndex: 'sales',
            className: 'cursor-pointer text-secondary align-center',
            title: t(section + '.list.header.sales.title'),
            responsive: ['xl'],
            width: 100,
            render: (value, record) => (<Tooltip title={t(section + '.list.header.sales.tooltip')}>
                <Text>{value}</Text>
                {value && record.hits ? <Text
                    type="secondary font-size-sm user-select-none"> / {roundPrice(value / record.hits, true, 10)}%</Text> : null}
            </Tooltip>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => navToExternal('order', record),
                    onAuxClick: (ev) => navToExternal('order', record, true),
                    onContextMenu: (ev) => (ev.preventDefault()),
                };
            },
        },

        {
            dataIndex: 'orders',
            className: 'cursor-pointer text-secondary align-center',
            title: t(section + '.list.header.orders.title'),
            responsive: ['md'],
            width: 100,
            render: (value, record) => (<Tooltip title={t(section + '.list.header.orders.tooltip')}>
                <Text>{value}</Text>
                {value && record.hits ? <Text
                    type="secondary font-size-sm user-select-none"> / {roundPrice(value / record.hits, true, 10)}%</Text> : null}
            </Tooltip>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => navToExternal('order', record),
                    onAuxClick: (ev) => navToExternal('order', record, true),
                    onContextMenu: (ev) => (ev.preventDefault()),
                };
            },
        },
        {
            dataIndex: 'leads',
            className: 'cursor-pointer text-secondary align-center',
            title: t(section + '.list.header.leads.title'),
            responsive: ['xl'],
            width: 100,
            render: (value, record) => (<Tooltip title={t(section + '.list.header.users.tooltip')}>
                <Text>{value}</Text>
                {value && record.hits ? <Text
                    type="secondary font-size-sm user-select-none"> / {roundPrice(value / record.hits, true, 10)}%</Text> : null}
            </Tooltip>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => navToExternal('user', record),
                    onAuxClick: (ev) => navToExternal('user', record, true),
                    onContextMenu: (ev) => (ev.preventDefault()),
                };
            },
        },
        {
            dataIndex: 'users',
            className: 'cursor-pointer text-secondary align-center',
            title: t(section + '.list.header.users.title'),
            responsive: ['md'],
            width: 100,
            render: (value, record) => (<Tooltip title={t(section + '.list.header.users.tooltip')}>
                <Text>{value}</Text>
                {value && record.hits ? <Text
                    type="secondary font-size-sm user-select-none"> / {roundPrice(value / record.hits, true, 10)}%</Text> : null}
            </Tooltip>),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => navToExternal('user', record),
                    onAuxClick: (ev) => navToExternal('user', record, true),
                    onContextMenu: (ev) => (ev.preventDefault()),
                };
            },
        },
        {
            dataIndex: 'hits',
            className: 'cursor-pointer text-secondary align-center',
            title: <SorterWrapper className="inline">
                <EyeOutlined title={t(section + '.list.header.hits.title')}/>
            </SorterWrapper>,
            sorter: true,
            responsive: ['lg'],
            width: 85,
            render: (value, record) => (<Tooltip title={t(section + '.list.header.hits.tooltip')}>
                <Text>{value}</Text>
            </Tooltip>),

            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => copyToClipboard(record.hits, t('common.result.copied.neuter'))
                };
            },
        },
        {
            key: 'menu',
            className: 'menu-column',
            width: 55,
            render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
        },
    ];


    const composeQuery = (record) => {
        let queryParts = []
        for (const key of ['id', 'source', 'medium', 'campaign', 'content', 'term', 'group']) {
            if (record[key]) {
                queryParts.push(`utm_${key}=${record[key]}`)
            }
        }
        return queryParts.join('&')
    }

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: t(section + '.list.menu.edit'),
            action: (record) => recordEdit(record)
        },
        // {
        //     key: 'tableMenuCopy',
        //     label: t('table.menu.duplicate'),
        //     action: (record) => recordCopy(record.id)
        // },
        {
            key: 'tableMenuLeads',
            label: t(section + '.list.menu.leads'),
            action: (record) => navToExternal('user', record)
        },
        {
            key: 'tableMenuOrders',
            label: t(section + '.list.menu.orders'),
            action: (record) => navToExternal('order', record)
        },
        {
            key: 'tableMenuBotRef',
            label: t(section + '.list.menu.bot_ref'),
            action: (record) => {
                copyToClipboard(`hit-1--utm-${record.id}`, t('common.result.copied.neuter'))
            }
        },
        {
            key: 'tableMenuAppRef',
            label: t(section + '.list.menu.app_ref'),
            action: (record) => {
                copyToClipboard(`?startapp=utm-${record.id}`, t('common.result.copied.neuter'))
            }
        },
        {
            key: 'tableMenuQueryParams',
            label: t(section + '.list.menu.query_params'),
            action: (record) => {
                copyToClipboard(
                    composeQuery(record),
                    t('common.result.copied.neuter')
                )
            }
        },
        {
            type: 'divider',
            render: (record) => <Divider className="margin-none-vertical" type="horizontal"/>,
            action: (record) => null
        },
        {
            key: 'tableMenuDelete',
            label: t(section + '.list.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: t('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const record = list[i]
        const stat = record.stat || {}
        data.push({
            id: record.id,
            key: record.id + '-listRow',
            title: record.title,
            hits: record.hits,
            users: stat.users || 0,
            leads: stat.leads || 0,
            orders: stat.orders || 0,
            sales: stat.sales || 0,
            income: stat.income || 0,

            source: record.source,
            medium: record.medium,
            campaign: record.campaign,
            content: record.content,
            term: record.term,
            group: record.group,

            deleted: record.deleted_at > 0,
        });
    }

    // ==== Selection ====

    const deleteSelected = () => {
        for (const projectKey of selectedRowKeys) recordDelete(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const restoreSelected = () => {
        for (const projectKey of selectedRowKeys) recordRestore(parseInt(projectKey))
        showCheckboxButtons(false);
    }

    const onSelectChange = selectedRowKeys => {
        if (selectedRowKeys.length > 0) showCheckboxButtons(true)
        else showCheckboxButtons(false);

        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onTableChange = (pagination, filters, sorter) => {
        dispatch(setUtmStoreParam({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sorter: sorter.order ? {order: sorter.order, field: sorter.field} : null,
        }))
    }

    // ==== Render ====

    return (
        <div>
            <ListTable
                showHeader={true}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                project={project}
                onTableChange={onTableChange}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: rowsTotal,
                    position: ['bottomLeft', 'bottomCenter'],
                }}
            />
            <div className={isCheckboxButtons ? "button-flying" : "hide"}>
                <Button
                    className={filters.is_deleted ? "hide" : ""}
                    onClick={deleteSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.delete')}</Button>
                <Button
                    className={filters.is_deleted ? "" : "hide"}
                    onClick={restoreSelected}
                    icon={<DeleteOutlined/>}>{t('common.action.restore')}</Button>
            </div>
        </div>
    )
}

