import React, {useState} from 'react'
import classes from '../../../../Styles/SmallButtons.module.scss';
import isNumeric from 'isnumeric'
import {getItemStorage, setItemStorage} from "../../../../../../../../../library/functions";
import List from "./List/List";
import {effects} from "../../../../../../../../../schemas/frontend/effects";
import {useTranslation} from 'react-i18next';

const sectionType = 'effect';

const Category = ({section, catList}) => {
    const {t} = useTranslation()
    const activeMode = getItemStorage(`${section}_${sectionType}`);
    const [activeButton, setActiveButton] = useState(activeMode ?? 0);

    // path params
    // const params = useParams()
    // const project_id = Number(params.project_id);

    const tabButtons = [];
    const categoryList = ['all', ...Object.keys(catList)];

    categoryList.forEach((cat, index) => {
        let containers;

        // console.log('cat', cat)
        const sec = (section === 'integration' && cat ===  'app') ? 'program' : section;

        if (cat === 'all') containers = effects.filter(effect => sec === effect.type.split('/')[1]);
        else containers = effects.filter(effect => sec === effect.type.split('/')[1] && cat === effect.type.split('/')[2]);

        // system section
        // if (cat === 'app' && !isSystemProject(project_id)) return;

        if (containers.length) {
            tabButtons.push({
                title: t(`${sectionType}.${section}.${cat}.title`),
                active: !index ? 'true' : 'false',
                module: <List containers={containers}/>
            })
        }
    })

    if (!tabButtons.length) return null; // for empty containers

    const changeActiveButton = (index_button) => {
        setItemStorage(`${section}_${sectionType}`, index_button);
        setActiveButton(index_button);
    }

    const onClickButton = (e) => {
        const index_button = Number(e.target.getAttribute('data-button'));
        if (isNumeric(index_button)) {
            changeActiveButton(index_button);
        }
    }

    const buttons = () => {
        tabButtons.forEach(bt => bt.active = 'false');
        if (Number(activeButton) > tabButtons.length - 1) {
            changeActiveButton(0);
            tabButtons[0].active = 'true';
        } else {
            tabButtons[Number(activeButton)].active = 'true';
        }

        return tabButtons.map((bt, index) => {
            return <div
                className={classes.small_button}
                data-button={index}
                data-active={bt.active}
                onClick={onClickButton}
                key={bt.title}
            >
                {bt.title}
            </div>
        })
    }

    return (
        <div className={classes.block_buttons}>
            {buttons()}
            {tabButtons[activeButton].module}
        </div>
    )
}

export default Category