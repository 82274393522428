import {createSlice} from "@reduxjs/toolkit";
import {setEncryptedStorageItem} from "../../library/functions";
import {images} from "../../config/config";

const AdminReducer = createSlice({

    name: 'admin',
    initialState: {
        authorized: null,
        correct: true,
        logging: false,
        moving: false, // for redirecting to projects
        item: {
            id: 0,
            username: 'string',
        },
        object: {}, // for editing by super admins
        params: null,
        user: {},
        token: {
            access_token: '',
            token_type: ''
        },
        header: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        header_authorized: {}, // правильно заполняется в setAccessToken()
        fields: {},
    },
    reducers: {
        setAdminItem(state, action) {
            // console.log('Reducer setAdminItem', action.payload)
            state.item.id = action.payload.id;
            state.item.username = action.payload.username;
            state.params = action.payload.params;

            setEncryptedStorageItem({
                item: state.item,
                user: state.user,
                params: action.payload.params,
                token: state.token
            });
        },
        setAdminObject(state, action) {
            state.object = action.payload;
        },
        setAdminUser(state, action) {
            // console.log('--- setAdminUser ---',action.payload)
            state.user = {...action.payload.user}

            if (action.payload.params) state.params = {...action.payload.params}

            let admin = {...state};
            if (!admin.user.photo_url) admin.user.photo_url = images.avatar.user
            setEncryptedStorageItem({item: admin.item, user: admin.user, params: admin.params, token: admin.token});
            // console.log('--- setAdminUser',state.user)
        },
        setAccessToken(state, action) {
            // console.log('--- setAccessToken ---')
            // console.log('--- setAccessToken',action.payload)
            const token = action.payload
            state.token = {...token}
            state.header_authorized = {
                ...state.header,
                'Authorization': `${token.token_type} ${token.access_token}`
            }
        },
        setAuthorized(state, action) {
            state.authorized = action.payload;
        },
        setLogging(state, action) {
            state.logging = action.payload;
        },
        setMoving(state, action) {
            state.moving = action.payload;
        },
        setCorrect(state, action) {
            state.correct = action.payload;
        },
        setLogOut(state) {
            // removeItemStorage(dotenv.app_name) // do in on logout page
            // console.log('--- setLogOut ---')
            state.authorized = false;
            state.logging = true;
            state.auth = {};
            state.user = {};
            state.token = {};
            state.header_authorized = {};
        },
        setAdminRestore(state, action) {
            state.auth = action.payload.auth;
            state.user = action.payload.user;
            state.params = action.payload.params;

            const token = action.payload.token;
            state.token = token;
            state.header_authorized = {
                ...state.header,
                'Authorization': `${token.token_type} ${token.access_token}`
            }

            state.authorized = true;
            state.logging = false;
        },
    },
})

export default AdminReducer.reducer

export const {
    setAdminItem,
    setAdminUser,
    setAccessToken,
    setAuthorized,
    setLogOut,
    setLogging,
    setMoving,
    setCorrect,
    setAdminRestore,
    setAdminObject,
} = AdminReducer.actions
