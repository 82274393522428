import React, {useState} from 'react'
import {Card, Typography} from "antd";

import Preloader from "../System/Preloader";
import {SimpleModal} from "../../components/Modal/SimpleModal";
import {useTranslation} from "react-i18next";
import classes from "../../components/Card/Store/StoreCard.module.scss";
import buttons_classes from "../../containers/Flow/components/ControlPanel/components/Styles/SmallButtons.module.scss";

import {PlusOutlined} from "@ant-design/icons";
import SearchContainers from "../Flow/components/ControlPanel/components/Search/SearchContainers";
import Fa from "../../components/Icon/Fa";

const {Title} = Typography

export const GraphExamplesModal = ({section, list, folders, isVisibleModal, setVisibleModal, onInstall}) => {
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState(0);
    const [valueState, setValueState] = useState('');

    if (!list || !folders) return <Preloader/>

    const categories = [
        {id: 0, title: t(section + '.examples.modal.tabs.all')},
        ...folders.map((folder) => {
            return {id: folder.id, title: folder.title}
        })
    ]

    const tabButtons = () => {
        return categories.map((folder) => {
            return <div
                className={buttons_classes.small_button}
                data-active={activeTab === folder.id ? 'true' : 'false'}
                onClick={() => setActiveTab(folder.id)}
                key={folder.id}
            >
                {folder.title}
            </div>
        })
    }

    return (
        <SimpleModal
            isVisible={isVisibleModal}
            setVisible={setVisibleModal}
            showFooter={false}
            title={t(section + '.examples.modal.title')}
            // title={null}
            width={1600}
        >

            <SearchContainers
                valueState={valueState}
                setValueState={setValueState}
                title={t(section + '.examples.modal.search.placeholder')}
                className="margin-bottom-sm"
            />

            {valueState ? null : <div className="flex flex-row flex-wrap margin-bottom-sm">
                {tabButtons()}
            </div>}

            <div className="flex flex-row flex-wrap gap-sm">
                {(activeTab || valueState) ? null : <Card
                    key="create"
                    className={`${classes.card} compact cursor-pointer`}
                    cover={<Fa icon="plus" set="light" wcn={classes.icon}/>}
                    bordered={false}
                    hoverable
                    onClick={() => {
                        onInstall(0)
                    }}
                >
                    <Title level={3} className={classes.title}>
                        <span>{t(section + '.examples.modal.create_new')}</span>
                    </Title>
                </Card>}

                {list.filter(item => (!valueState && !activeTab) ||
                    (!valueState && activeTab && item.folder_id === activeTab) ||
                    (valueState && item.title.toLowerCase().includes(valueState))
                ).map(item => {
                    const cover = <div
                        className={classes.photo_wrapper}
                        style={item.params?.photo_url ? {backgroundImage: 'url(' + item.params.photo_url + ')'} : null}
                    >
                        <PlusOutlined className={classes.open_icon}/>
                        <div className={classes.photo_overlay}/>
                        {/*<img alt="Template photo" src={}/>*/}
                    </div>

                    return <Card
                        key={section + '-' + item.id}
                        className={`example-card compact cursor-pointer ${classes.card}`}
                        cover={cover}
                        onClick={() => {
                            onInstall(item.id)
                        }}
                    >
                        <Title level={3} className={classes.title}>
                            <span>{item.title}</span>
                        </Title>
                    </Card>
                })}
            </div>
        </SimpleModal>
    )

}