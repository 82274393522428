import React, {useEffect} from 'react'
import classes from './LoginAdmin.module.scss'
import {Button, Form, Input} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dotenv, images, routes} from '../../config/config'
import {useTranslation} from 'react-i18next';
import Scrollbar from "react-scrollbars-custom";
import {setLogging, setMoving} from "../../redux/reducers/AdminReducer";

const AuthFormAdmin = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlTail = window.location.search || ''

    const {admin} = useSelector(store => store)

    useEffect(() => {
        dispatch(setLogging(true));
        //eslint-disable-next-line
    }, [])

    const onFinish = (values) => {
        dispatch(setMoving(true));
        const data = {username: values.username, password: values.password}
        dispatch({type: 'getAccessToken', data});
    }
    const onFinishFailed = (errorInfo) => console.log('Form failed:', errorInfo.values);

    return (
        <Scrollbar>
            <div className={`${classes.container} ${classes.container_auth} auth-form`}>
                <div className={classes.form_container}>
                    <div className={classes.wrapper_form}>
                        <a href={routes.root} className={classes.form_logo_multy}>
                            <img src={images.logo_small} alt='logo color'/>
                        </a>
                        <div className={classes.form_head}>{t('auth.form.title')}</div>
                        {/*<div className={classes.reg_social_linkblock}>*/}
                        {/*    <Link className={classes.reg_social} to='/'>*/}
                        {/*        <div className={`${classes.wrapper_icon} ${classes.wrapper_icon_vk}`}>*/}
                        {/*            <FaVk className={classes.faIcon}/>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.reg_social_text}>{t('platform.vk.title')}</div>*/}
                        {/*    </Link>*/}
                        {/*    <Link className={classes.reg_social} to='/'>*/}
                        {/*        <div className={`${classes.wrapper_icon} ${classes.wrapper_icon_tg}`}>*/}
                        {/*            <FaTelegramPlane className={`${classes.faIcon} ${classes.faTg}`}/>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.reg_social_text}>{t('platform.tg.title')}</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className={classes.hor_line}>*/}
                        {/*    <div className={classes.hor_line_title}>{t('common.or')}</div>*/}
                        {/*</div>*/}
                        <Form
                            className={classes.auth_form}
                            name='basic'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            // autoComplete='off'
                        >
                            <Form.Item className={`${classes.form_item} ${classes.form_item_fields}`}
                                       name='username'
                                       rules={[{required: true, message: t('auth.fields.login.error.required')}]}
                            >
                                <Input
                                    className={classes.input_field}
                                    type={'text'}
                                    placeholder={t('auth.fields.login.title')}/>
                            </Form.Item>
                            <Form.Item className={`${classes.form_item} ${classes.form_item_fields}`}
                                       name='password'
                                       rules={[{required: true, message: t('auth.fields.password.error.required')}]}
                            >
                                <Input.Password
                                    className={classes.input_field}
                                    type={'password'}
                                    placeholder={t('auth.fields.password.title')}/>
                            </Form.Item>

                            <Form.Item className={`${classes.form_item} ${classes.form_item_button}`}>
                                <div className={classes.wrapper_buttons}>
                                    <Button
                                        className={classes.form_button_submit}
                                        loading={admin.moving}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('auth.fields.submit.title')}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        {/*<div className={classes.have_account}>*/}
                        {/*    <div className={classes.have_account_answer}>{t('auth.links.forgot.answer')}</div>*/}
                        {/*    <div className={classes.have_account_action}*/}
                        {/*         onClick={() => {*/}
                        {/*             navigate(routes.forgot)*/}
                        {/*         }}>*/}
                        {/*        {t('auth.links.forgot.action')}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {dotenv.is_registration_allowed && <div className={classes.have_account}>
                            <div className={classes.have_account_answer}>{t('auth.links.forgot.answer')}</div>
                            <div className={classes.have_account_action}>
                                <a href={dotenv.support_url}>{t('auth.links.support.action')}</a>
                            </div>
                        </div>}
                        {dotenv.is_app_free ? null : <div className={classes.have_account} style={{marginTop: 0}}>
                            <div className={classes.have_account_answer}>{t('auth.links.register.answer')}</div>
                            <div className={classes.have_account_action}
                                 onClick={() => {
                                     navigate(routes.reg + urlTail)
                                 }}>{t('auth.links.register.action')}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </Scrollbar>
    )
}

export default AuthFormAdmin