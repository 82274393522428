import React, {useEffect, useLayoutEffect, useState, useRef} from 'react'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Scrollbar from "react-scrollbars-custom";
import moment from "moment-timezone";

import classes from './Dialogs.module.scss';
import app_classes from '../../Layouts/AppWrapper/AppWrapper.module.scss';

import {notice} from "../../../library/notice";
import {backend_api, images, routes, screens} from "../../../config/config";
import {messengers, popularEffectsTypes} from "../../../schemas/frontend/effects";
import {Avatar, Layout, List, Result, Tooltip, Form, Button, Typography, Input, Alert} from "antd";
import {
    ArrowLeftOutlined,
    AudioOutlined,
    CoffeeOutlined,
    EditOutlined,
    ExportOutlined,
    IdcardOutlined,
    LinkOutlined,
    PaperClipOutlined,
    PictureOutlined,
    SendOutlined,
    SoundOutlined,
    StarOutlined,
    UserOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";

import {
    convertLineBrakeToBr,
    createMarkup,
    createObjectFromObjectsArray,
    deepGet,
    getUserFullName,
    momentFromUnix,
    objectLength,
    parseBool,
    redirect,
    stripHtmlTags,
    toNum,
    is,
    ucFirst,
    urlsToLinks,
    inArray,
    getUtcDateTime,
    trimMiddle
} from "../../../library/functions";

import LeftPanel from "../../Layouts/AppWrapper/LeftPanel/LeftPanel";
import {createObjectFromIntegrations} from "../../Integrations/IntegrationEdit";
import {useTranslation} from "react-i18next";
import {fillDialogMessages, setDialogItem} from "../../../redux/reducers/DialogReducer";
import {CheckboxField, submitFormByHotkeys} from "../../../components/Form/Field/FormFields";
import {fillUserList} from "../../../redux/reducers/UserReducer";
import Fa from "../../../components/Icon/Fa";
import {makePostRequest} from "../../../library/api";
import {apiErrorNotice, apiFalseNotice} from "../../../library/error";
import {SimpleModal} from "../../../components/Modal/SimpleModal";
import Preloader from "../../System/Preloader";
import {patchProjectInList} from "../../../redux/reducers/ProjectReducer";
import {dispatchItemData} from "../../../library/containers";
import {setContactItem} from "../../../redux/reducers/ContactReducer";

const {Content} = Layout;
const {Title, Text} = Typography;

const updateInterval = 4000; // 1000
const messagesPerPage = 100;  // 100

let createdSinceMessages = 0;
let createdUntilMessages = 0;

let lastScrolledCoords = 0;
let oldestMessageReached = false;

let messagesScrolledToTop = false;
let messagesScrolledToBottom = false;

// prevent second scroll till old items loaded
let listScrolledToBottom = false;
let newestDialogStamp = 0;

const DialogList = () => {
    const {t} = useTranslation();

    // init section name
    const section = 'dialog'
    const categorySection = 'integration'

    // data from URL params
    const params = useParams()
    const id = toNum(params.id)
    const project_id = toNum(params.project_id)

    // data from GET params
    const [searchParams] = useSearchParams();
    const userIdParam = toNum(searchParams.get('user_id'));

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // refs
    const textareaRef = useRef(null);
    const listScrollRef = useRef(null);
    const messagesScrollRef = useRef(null);

    // store receive
    const {admin, project, dialog, user, contact, integration, employee} = useSelector(store => store)
    const storeUsers = user.list || [];
    const bots = createObjectFromIntegrations(t, integration.list, false);
    const managers = createObjectFromObjectsArray(employee.list);

    // init universal vars
    const project_item = project.item
    const contact_item = contact.item
    const item = dialog.item || {}
    const items = dialog.list || []
    const sorter = dialog.sorter
    const forbidden = id > 0 && contact_item.id && (
        !contact_item.is_allowed ||
        (contact_item.last_message_at && contact_item.last_message_at < (getUtcDateTime() - 86400))
    )

    // console.log('contact_item', contact_item.is_allowed, contact_item.last_message_at, getUtcDateTime() - 86400, contact_item.last_message_at < (getUtcDateTime() - 86400));

    if (item.list.length) {
        const oldestMessage = item.list[item.list.length - 1] || {};
        if (oldestMessage.created_at && (!createdUntilMessages || oldestMessage.created_at < createdUntilMessages)) {
            createdUntilMessages = oldestMessage.created_at;
        }

        const newestMessage = item.list[0] || {};
        if (newestMessage.created_at && (!createdSinceMessages || newestMessage.created_at > createdSinceMessages)) {
            createdSinceMessages = newestMessage.created_at;
        }
    }

    const rowsTotal = dialog.count || 0
    const currentPage = dialog.page || 1
    const pageSize = dialog.pageSize || 100
    // const pageSize = 2

    const [form] = Form.useForm();

    // states init
    const [sending, setSending] = useState(false)
    const [filters, setFilters] = useState({})
    const [users, setUsers] = useState({})
    const [list, setList] = useState([])
    const [messages, setMessages] = useState([])
    const [modalPhotoUrl, setModalPhotoUrl] = useState('')

    let currentUserId = userIdParam;
    if (messages && messages.length) currentUserId = messages[0].user_id;
    const currentUser = users[currentUserId]
    // console.log('currentUserId', currentUserId, users[currentUserId]);

    const newestDialog = list[0] || {};
    newestDialogStamp = toNum(newestDialog.affected_at);

    const oldestDialog = list[list.length - 1] || {};
    const oldestDialogStamp = toNum(oldestDialog.affected_at);

    const getList = (filters) => {
        dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters})
    }

    const resetList = () => {
        setList([]);
        dispatch(fillDialogMessages([]));
    }

    const getMessages = (filters) => {
        dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id: id, filters: filters})
    }

    const resetMessages = () => {
        createdUntilMessages = 0;
        setMessages([]);

        // reset pages counter too
        dispatch(setDialogItem({id: 0, list: [], count: 0, page: 1}));

        // reset scrolling helpers
        oldestMessageReached = false;
        lastScrolledCoords = 0;
    }

    useLayoutEffect(() => {
        // console.log('useLayout - Reset messages and form')
        resetMessages();

        dispatch(fillUserList([]));
        form.setFieldsValue({record: {message: ''}, spec: {handle_vars: true}});
    }, []);

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            // first get bots...
            dispatch({
                type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {
                    project_id: project_item.id,
                    platforms: messengers
                }
            })

            // ... and managers
            dispatch({
                type: 'getEmployeeList', admin, filters: {
                    project_id: project_item.id,
                    page_size: 200,
                }
            })

            let ordering = '-created_at'
            // if (sorter && objectLength(sorter)) {
            //     let prefix = sorter.order === 'ascend' ? '' : '-'
            //     ordering = prefix + sorter.field
            // }

            let filters_result = {
                project_id: project_item.id,
                page_size: pageSize,
                page_number: currentPage,
                ordering: ordering,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}
            getList(filters_result)

            // auto receive item data every 1 sec with arg.filters = {created_since: messages[0].affected_at}:
            const interval = setInterval(() => {
                console.info('Get lists by Interval from', momentFromUnix(newestDialogStamp).format('DD.MM.YYYY HH:mm:ss:SSS'))

                getList({...filters_result, affected_since: newestDialogStamp})
            }, updateInterval);

            return () => {
                // console.log('useEffect return → resetList()')
                clearInterval(interval);
                resetList();
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter])

    useEffect(() => {
        // console.log('useEffect handle list', items)

        if (items.length) {
            // handle list and receive users
            let userIds = []
            for (const item of items) {
                if (!users[item.user_id]) userIds.push(item.user_id)
            }

            if (userIdParam) userIds.push(userIdParam)
            if (userIds.length) dispatch({
                type: 'getUserList', admin, filters: {
                    project_id: project_id,  // do not use project_item.id to support project fast switching
                    ids: userIds,
                    deleted_since: 0, // get all users (even Deleted)
                }
            })

            if (id > 0 && contact_item.id) {
                for (const item of items) {
                    const itemContact = item.contact || {}
                    if (itemContact.id !== contact_item.id) continue

                    const isAllowed = itemContact.is_allowed !== false;
                    if (contact_item.is_allowed !== isAllowed) {
                        dispatch(setContactItem({...contact_item, is_allowed: isAllowed}))
                    }

                    // console.info('itemContact', isAllowed, itemContact, contact_item)
                    break
                }
            }

            // add new items to list
            if (!list.length) setList([...items])
            else {
                const oldestNewDialog = items[items.length - 1] || {};
                const oldestCurrentDialog = list[list.length - 1] || {};

                const newestNewDialog = items[0] || {};
                const newestCurrentDialog = list[0] || {};

                // console.log('compare', toNum(newestCurrentDialog.affected_at), '<=' ,toNum(oldestNewDialog.affected_at), oldestNewDialog, newestNewDialog)

                if (toNum(newestCurrentDialog.affected_at) < toNum(oldestNewDialog.affected_at)) {
                    // remove duplicates from old list items:
                    let newList = []
                    for (const listItem of list) {
                        let duplicate = false
                        for (const newItem of items) {
                            if (listItem.id === newItem.id) {
                                duplicate = true
                                break
                            }
                        }
                        if (!duplicate) newList.push(listItem)
                    }

                    // store new items to list
                    setList([...items, ...newList])

                } else if (toNum(oldestCurrentDialog.affected_at) > toNum(newestNewDialog.affected_at)) {
                    if (listScrolledToBottom) {
                        setList([...list, ...items]);
                        listScrolledToBottom = false;
                    }
                } else {
                    setList([...items])
                }
            }
        } else if (userIdParam) {
            dispatch({
                type: 'getUserList', admin, filters: {
                    project_id: project_id,  // do not use project_item.id to support project fast switching
                    ids: [userIdParam],
                    deleted_since: 0, // get all users (even Deleted)
                }
            })
        }

        //eslint-disable-next-line
    }, [admin.authorized, items])

    useEffect(() => {
        if (id) {
            // focus on textarea on dialog open
            if (textareaRef.current && window.innerWidth >= screens.md) textareaRef.current.focus();

            // for dialog creating (and messages sending)
            dispatchItemData(dispatch, admin, project_item, 'contact', id)

            // get dialog messages
            if (admin.authorized && project_item.id) {
                console.log('Get messages for', id);
                messagesScrolledToBottom = false;

                getMessages({
                    page_number: 1,
                    page_size: messagesPerPage
                })

                // auto receive item data every 1 sec with arg.filters = {created_since: messages[0].created_at}:
                const interval = setInterval(() => {
                    console.log('Get messages by Interval for', id);

                    getMessages({created_since: createdSinceMessages})
                }, updateInterval);

                return () => {
                    clearInterval(interval);
                    resetMessages();
                }
            }
        } else {
            resetMessages();
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id])

    useEffect(() => {
        if (id) {
            // set read status for item: (id == record.id):
            const strId = String(id);
            const index = list.findIndex(item => item.id === strId);
            // console.log('findIndex', index, list[index], list)
            if (index !== -1 && !deepGet(list[index], ['status'])) {
                const oldItem = list[index];
                const newItem = {...oldItem, status: 1};

                const newList = [...list];
                newList[index] = newItem;
                setList(newList);
            }
        }
        //eslint-disable-next-line
    }, [list, id])

    useEffect(() => {
        // author-users receiving
        let authorIds = []
        for (const mess of item.list) {
            if (
                mess.author && mess.author.user_id &&
                mess.author.user_id !== mess.user_id &&
                !users[mess.author.user_id] &&
                !inArray(mess.author.user_id, authorIds)
            ) authorIds.push(mess.author.user_id)
        }

        if (userIdParam && !currentUserId) authorIds.push(userIdParam)
        if (authorIds.length) dispatch({
            type: 'getUserList', admin, filters: {
                project_id: project_id,
                ids: authorIds,
                deleted_since: 0, // get all users (even Deleted)
            }
        })

        const itemsLength = item.list.length;
        if (itemsLength) {
            const lastNewMessage = item.list[itemsLength - 1] || {};
            const firstOldMessage = messages[0] || {};

            // console.log('LOAD messages', toNum(lastNewMessage.created_at) < toNum(firstOldMessage.created_at), toNum(lastNewMessage.created_at), ' < ' , toNum(firstOldMessage.created_at))
            if (toNum(lastNewMessage.created_at) < toNum(firstOldMessage.created_at)) {
                // load more OLD messages and add to start of list
                // messagesScrolledToTop = false;
                setMessages([...messages, ...item.list])

                // stop loading if no more messages
                if (itemsLength < messagesPerPage) oldestMessageReached = true;
            } else {
                // add NEW messages to end of list
                // messagesScrolledToTop = true;
                setMessages([...item.list, ...messages])
            }

            // reset message records for next adding
            dispatch(fillDialogMessages([]));
        }

        //eslint-disable-next-line
    }, [admin.authorized, item.list])

    useEffect(() => {
        if (sending) {
            // clear form fields
            form.setFieldsValue({record: {message: ''}});
            setSending(false);
        }

        const curScroll = messagesScrollRef.current;
        if (!curScroll || !messages.length) return;

        const parentContainer = document.getElementById("messages");
        const images = parentContainer.querySelectorAll('img');

        const scrollToLast = (resetCoords = true) => {
            // console.log(
            //     'scrollToLast lastScrolledCoords',
            //     curScroll.scrollHeight, '-', curScroll.clientHeight, '-', lastScrolledCoords, '=',
            //     curScroll.scrollHeight - curScroll.clientHeight * 0.3 - lastScrolledCoords
            // )
            messagesScrollRef.current.scrollTo(0, curScroll.scrollHeight - curScroll.clientHeight * 0.15 - lastScrolledCoords);
            if (resetCoords) lastScrolledCoords = 0;
        }

        const removeImagesLastEvents = () => {
            for (const img of images) {
                img.removeEventListener('load', lastWhenImagesLoaded);
            }
        }

        const lastWhenImagesLoaded = () => {
            const allImagesLoaded = Array.from(images).every(img => img.complete);
            if (allImagesLoaded) {
                scrollToLast();
                removeImagesLastEvents();
            }
        };

        const scrollToBottom = () => {
            messagesScrollRef.current.scrollToBottom();
            messagesScrolledToBottom = true;
        }

        const removeImagesBottomEvents = () => {
            for (const img of images) {
                img.removeEventListener('load', bottomWhenImagesLoaded);
            }
        }

        const bottomWhenImagesLoaded = () => {
            const allImagesLoaded = Array.from(images).every(img => img.complete);
            if (allImagesLoaded) {
                scrollToBottom();
                removeImagesBottomEvents();
            }
        };

        if (lastScrolledCoords) {
            // console.log('lastScrolledCoords', lastScrolledCoords)

            scrollToLast(false);

            // add load listener for each image
            for (const img of images) img.addEventListener('load', lastWhenImagesLoaded);

            // Call checkImagesLoaded once to handle images that are already loaded.
            lastWhenImagesLoaded();

            return () => {
                removeImagesLastEvents();
            };
        } else {
            // console.log(
            //     'messagesScrolledToBottom', messagesScrolledToBottom,
            //     '\nscrollHeight', curScroll.scrollHeight,
            //     '\nscrollTop', curScroll.scrollTop,
            //     '\nclientHeight', curScroll.clientHeight,
            //     '\ncompare',
            //     curScroll.scrollHeight - curScroll.scrollTop - curScroll.clientHeight < curScroll.clientHeight * 0.7,
            //     curScroll.scrollHeight - curScroll.scrollTop - curScroll.clientHeight, '<', curScroll.clientHeight * 0.7,
            // )

            // add scrolled 0.7 screen from bottom restrict auto scroll:
            if (!messagesScrolledToBottom || curScroll.scrollHeight - curScroll.scrollTop - curScroll.clientHeight < curScroll.clientHeight * 0.7) {
                // messagesScrollRef.current.scrollToBottom();

                if (!is(images)) scrollToBottom();
                else {
                    scrollToBottom();

                    // add load listener for each image
                    for (const img of images) img.addEventListener('load', bottomWhenImagesLoaded);

                    // Call checkImagesLoaded once to handle images that are already loaded.
                    bottomWhenImagesLoaded();

                    return () => {
                        removeImagesBottomEvents();
                    };
                }
            }
        }

        //eslint-disable-next-line
    }, [messagesScrollRef, messages])

    useEffect(() => {
        if (storeUsers.length > 0) {
            let newUsers = {...users}
            for (const user of storeUsers) {
                newUsers[user.id] = user
            }

            // console.log('newUsers', newUsers)
            setUsers(newUsers)
            dispatch(fillUserList([]));
        }
        //eslint-disable-next-line
    }, [storeUsers])

    const recordCreate = (folder_id = 0) => {
        let url = `${routes.project_list}/${project_item.id}/${section}/edit/0`
        if (filters.site_id) url += `&site_id=${filters.site_id}`
        navigate(url)
    }

    const searchDialogs = (value) => {
        setFilters({...filters, user_name: value})
    }

    const openDialog = (item_id = 0) => {
        const itemId = Number(item_id)
        if (itemId === id) return

        // dispatch(setDialogItem({id: itemId, list: [], count: 0, page: 1}));
        // setMessages([]);

        navigate(`${routes.project_list}/${project_item.id}/${section}/${itemId}`)
    }

    const closeDialog = () => {
        navigate(`${routes.project_list}/${project_item.id}/${section}`)
    }

    const onListScroll = (values) => {
        let filters_result = {project_id: project_item.id, page_size: pageSize}
        if (objectLength(filters)) filters_result = {...filters_result, ...filters}

        // console.log(values)
        // Object { clientHeight: 80, clientWidth: 339, contentScrollHeight: 146, contentScrollWidth: 339, scrollHeight: 146, scrollWidth: 339, scrollTop: 66, scrollLeft: 0, scrollYBlocked: undefined, scrollXBlocked: undefined, … }

        if ((values.clientHeight + values.scrollTop) === values.scrollHeight) {
            // console.info('LIST scrolled to bottom', values)

            listScrolledToBottom = true;
            getList({...filters_result, affected_until: oldestDialogStamp})
        }
    }

    const userUrl = (id) => `${routes.project_list}/${project_item.id}/user/profile/${id}`
    const platformDialogUrl = (message, group_id = null, toMess = false) => {
        if (!message || !message.contact) return null;
        let groupId = group_id;

        if (groupId === null) {
            const bot_id = message.contact.bot_id;

            const currentBot = integration.list.find(item => item.integration.id === bot_id);
            if (!currentBot) return null;

            groupId = deepGet(currentBot, 'integration.unique_hash');
        }

        if (!groupId || message.contact.type !== 'vk_id') return null;

        let url = `https://vk.com/gim${groupId}?sel=${message.contact.value}`
        if (toMess) url += '&msgid=' + deepGet(message, 'context.item.id')
        return url
    }

    const onMessagesScroll = (values) => {
        // console.log(values.scrollTop, values.clientHeight, values.scrollHeight)
        if (!oldestMessageReached && values.scrollTop === 0 && values.clientHeight !== values.scrollHeight) {
            // console.log('messages scrolled to top', values.scrollHeight)
            lastScrolledCoords = values.scrollHeight;
            getMessages({created_until: createdUntilMessages, page_size: messagesPerPage})
        }
    };

    let sendMessage = (values) => {
        // console.log('values', values)
        if (!values.record.message) {
            notice.warning(t(section + '.error.text_required'));
            return;
        }

        const currentItem = messages[0]
        if (!currentItem && !contact_item.id) {
            notice.error(t(section + '.error.data_not_found'));
            return;
        }

        let data = {
            record: {
                message: values.record.message,
                // attachments: values.record.attachments,

                project_id: project_item.id,
                user_id: currentUserId,

                direction: 'outcome',
                type: 'message',

                event: {
                    type: popularEffectsTypes.message_send
                },

                contact: currentItem ? currentItem.contact : {
                    'id': contact_item.id,
                    'type': contact_item.type,
                    'value': contact_item.value,
                    'bot_id': contact_item.bot_id,
                },
                author: {
                    type: 'manager',
                    manager_id: admin.user.id,
                },

                status: {
                    read: true,
                    answer: true
                },
                context: {
                    item: {
                        id: 0
                        // set item ID by backend message func
                    }
                }
            },
            spec: {
                contact_id: id,
                handle_vars: parseBool(values.spec.handle_vars)
                // handle_vars: true
            }
        }

        setSending(true);
        dispatch({type: 'sendDialogMessage', admin, data})
        // console.log('sendMessage', data)

        // imitate project messages decrease
        let newProjectValues = {}
        if (project_item.daily_messages > 0) {
            newProjectValues['daily_messages'] = project_item.daily_messages - 1
        } else if (project_item.permanent_messages > -project_item.daily_count * 10) {
            newProjectValues['permanent_messages'] = project_item.permanent_messages - 1
        }
        if (objectLength(newProjectValues)) {
            dispatch(patchProjectInList({...project_item, ...newProjectValues}))
        }
    }

    const renderDateTime = (record, className = 'inline', timeOnly = false) => {
        const createdMoment = momentFromUnix(record.created_at)
        let strDate = ''

        if (createdMoment.isSame(moment(), 'day') || timeOnly) strDate = createdMoment.format('HH:mm')
        else strDate = createdMoment.format('DD MMM')

        return <span className={className}>
            <Tooltip title={createdMoment.format('DD MMMM YYYY HH:mm')}>
                {strDate}
            </Tooltip>
        </span>
    }

    const downloadFiles = async (messageId, attachIndex = 0) => {
        // console.log('downloading...', messageId)
        try {
            const response = await makePostRequest(admin.token, backend_api.dialog + '/attachment', {
                message_id: messageId,
                index: attachIndex,
            });

            const attachment = deepGet(response, 'result')

            if (attachment && attachment.redirect_url) {
                redirect(attachment.redirect_url)
                return
            }

            if (!attachment || !attachment.photo_url) {
                apiFalseNotice(response)
                return
            }

            const messageIndex = messages.findIndex(item => item.id === messageId)
            if (messageIndex === -1) {
                notice.error('Message not found')
                return
            }

            let newMessage = {...messages[messageIndex]}
            const attachments = {...newMessage.attachments}

            attachments[attachIndex] = {...attachments[attachIndex], ...attachment}
            newMessage['attachments'] = attachments

            let newMessages = [...messages]
            newMessages[messageIndex] = newMessage
            setMessages(newMessages)
        } catch (error) {
            apiErrorNotice(error)
        }
    }

    const renderMessage = (record, recordClasses) => {
        let attachments = []

        const attachIcons = {
            document: <PaperClipOutlined/>,
            photo: <PictureOutlined/>,
            video: <VideoCameraOutlined/>,
            sticker: <StarOutlined/>,
            voice: <AudioOutlined/>,
            audio: <SoundOutlined/>,
            link: <LinkOutlined/>,
            story: <VideoCameraOutlined/>,
            story_mention: <VideoCameraOutlined/>,
            video_note: <VideoCameraOutlined/>,
            animation: <IdcardOutlined/>,
        }

        if (record.attachments) {
            for (const [key, attachment] of Object.entries(record.attachments)) {
                if (inArray(attachment.type, ['photo', 'sticker', 'animation'])) {
                    attachments.push(<div key={attachment.code} className="attachment">
                        {attachment.photo_url ?
                            <a className="nowrap" onClick={() => setModalPhotoUrl(attachment.photo_url)}>
                                <img
                                    src={attachment.photo_url}
                                    alt={attachment.code}
                                    className={"border-radius-sm margin-top-xs margin-bottom-xs type-" + attachment.type}
                                    loading="lazy"
                                />
                            </a> :
                            <Button
                                type="text"
                                onClick={() => downloadFiles(record.id, key)}
                                icon={attachIcons[attachment.type]}
                                className="padding-none nowrap"
                            >
                                {t(section + '.item.attachment.download.' + attachment.type)}
                            </Button>
                        }
                    </div>)
                }
                    // else if (attachment.type === 'video') {
                    //     // is it possible to embed video into dialog?
                    //     // <iframe src="https://vk.com/video_ext.php?oid=26187274&id=456239274&hash=5b007a7b8e061bd9" width="426" height="240" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
                // }
                else if (inArray(attachment.type, ['audio', 'voice']) && attachment.url) {
                    // const attachmentUrl = attachment.url.split('?')[0]
                    // const fileFormat = attachmentUrl.split('.').pop()
                    // const fileTypesMap = {
                    //     mp3: 'audio/mpeg',
                    //     ogg: 'audio/ogg',
                    //     oga: 'audio/ogg',
                    //     wav: 'audio/wav',
                    //     mp4: 'audio/mp4',
                    // }
                    // const fileType = fileTypesMap[fileFormat] || 'audio/mpeg'

                    attachments.push(<div key={attachment.code} className="attachment">
                        <audio controls className="border-radius-md margin-top-xs">
                            <source src={attachment.url}/>
                            {t(section + '.item.attachment.error.video')}
                        </audio>
                        {/*<Button
                            type="text"
                            onClick={() => downloadFiles(record.id, key)}
                            icon={attachIcons[attachment.type]}
                            className="padding-none nowrap"
                        >
                            {t(section + '.item.attachment.download.' + attachment.type)}
                        </Button>*/}
                    </div>)
                } else if (inArray(attachment.type, ['video', 'video_note']) && attachment.url) {
                    attachments.push(<div key={attachment.code} className="attachment">
                        <video controls className="border-radius-md margin-top-xs">
                            <source src={attachment.url}/>
                            {t(section + '.item.attachment.error.video')}
                        </video>
                    </div>)
                } else if (attachIcons[attachment.type]) {
                    let attachmentName = attachment.name;
                    if (record.contact.type === 'vk_id' && attachment.code) attachmentName = attachment.code
                    else if (!attachmentName && attachment.url) attachmentName = attachment.url.split('/').pop()
                    if (!attachmentName && attachment.code) attachmentName = attachment.code

                    attachments.push(<div key={attachment.code} className="attachment">
                        {attachment.url ? <Button
                            href={attachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            icon={attachIcons[attachment.type]}
                            type="link"
                            className="padding-none nowrap"
                            title={attachment.code}
                        >
                            {trimMiddle(attachmentName, 40)}
                        </Button> : <Button
                            type="text"
                            onClick={() => downloadFiles(record.id, key)}
                            icon={attachIcons[attachment.type]}
                            className="padding-none nowrap"
                        >
                            {t(section + '.item.attachment.download.' + attachment.type)}
                        </Button>}
                    </div>)
                } else {
                    attachments.push(<div key={attachment.code} className="attachment color-secondary">
                        <Tooltip title={attachment.name ?? attachment.code}>
                            <PaperClipOutlined className="margin-right-xp"/>
                            {t(section + '.item.attachment.unsupported')}: {attachment.type}
                        </Tooltip>
                    </div>)
                }
            }
        }

        let messageText = convertLineBrakeToBr(record.message || '')
        messageText = urlsToLinks(messageText)

        const isIncome = record.direction === 'income'

        return <div className={recordClasses}>
            <div className="time float-right user-select-none">
                {deepGet(record, ['status', 'edit']) ? <Tooltip
                    title={isIncome ? t(section + '.item.message.edited') : t(section + '.item.message.edit')}
                    placement={isIncome ? "top" : "topRight"}
                >
                    <EditOutlined className="margin-right-xs"/>
                </Tooltip> : null}
                {renderDateTime(record, classes.date, true)}
            </div>
            {record.message ? <>
                <span dangerouslySetInnerHTML={createMarkup(messageText)}/>
                <div className="clearfix"/>
            </> : (
                record.attachments
                && record.attachments.length
                && inArray(record.attachments[0].type, ['video', 'photo'])
                && (record.attachments[0].photo_url || record.attachments[0].url
            ) ? <>
                <span className="text-secondary">{t(section + '.item.message.empty')}</span>
                <div className="clearfix"/>
            </>: null)}
            {attachments.length ? <div className="attachments inline">{attachments}</div> : null}
        </div>
    }

    const renderMessages = () => {
        let records = []
        let recordsMap = {}

        const itemsLen = messages.length
        if (!itemsLen) return null

        const itemsCount = itemsLen - 1
        for (let i = itemsCount; i >= 0; i--) {
            const record = messages[i]

            if (recordsMap[record.id]) continue;
            recordsMap[record.id] = 1

            const prevRecord = messages[i + 1]
            const nextRecord = messages[i - 1]

            let user = {...users[record.user_id]}

            // let isFirst = !nextRecord || record.direction !== nextRecord.direction || record.created_at - nextRecord.created_at > 180 || record.user_id !== prevRecord.user_id;
            // let isLast = !prevRecord || record.direction !== prevRecord.direction || prevRecord.created_at - record.created_at > 180 || record.user_id !== prevRecord.user_id;

            let isFirst = !prevRecord || record.direction !== prevRecord.direction || record.user_id !== prevRecord.user_id;
            let isLast = !nextRecord || record.direction !== nextRecord.direction || record.user_id !== nextRecord.user_id;

            // render date if first message of the day
            const createdMoment = momentFromUnix(record.created_at)
            const prevCreatedMoment = prevRecord ? momentFromUnix(prevRecord.created_at) : null;

            if (i === itemsCount || !prevRecord ||
                createdMoment.format('DD-MM-YYYY') !== prevCreatedMoment.format('DD-MM-YYYY')
            ) {
                records.push(<div key={record.id + 'date'} className={`${classes.message} ${classes.day} align-center`}>
                    {createdMoment.format('DD MMMM')}
                </div>)

                // add some margins to message groups
                isFirst = true
            }

            let recordClasses = 'message inline'
            if (isFirst) recordClasses += ' first'
            if (isLast) recordClasses += ' last'

            if (record.direction === 'system') {
                let message = ''
                let dateStr = momentFromUnix(record.created_at).format('DD MMMM YYYY HH:mm')

                if (record.const || record.type === 'error') {
                    if (record.const === true || record.type === 'error') {
                        message = t(record.event.type.split('/').join('.') + '.title')
                    } else {
                        message = t(record.const)
                    }
                } else if (record.message) message = record.message

                let messageClasses = classes.message + ' align-center '
                if (record.type === 'error') {
                    messageClasses += classes.danger
                    if (record.message) dateStr += '\n' + record.message
                } else messageClasses += classes.system

                records.push(<div key={record.id} className={messageClasses}>
                    <Tooltip title={dateStr}>
                        {message}
                    </Tooltip>
                </div>)

            } else if (record.direction === 'income') {
                records.push(<div key={record.id} className={`${classes.message} align-left`}>
                    {isFirst ? <div className={`${classes.name} padding-top-xs`}>{getUserFullName(user)}</div> : null}
                    {renderMessage(record, recordClasses)}
                    {/*// message={getUserFullName(user)}*/}
                </div>)
            } else {
                let userName = 'Unknown';
                // console.log('bots', userName, record.author.bot_id, bots)

                if (record.author.manager_id === admin.user.id) {
                    userName = getUserFullName(admin.user)
                } else if (record.author.manager_id) {
                    const manager = managers[String(record.manager_id)]
                    if (manager) userName = manager
                    else userName = 'Manager ID ' + record.author.manager_id
                } else if (record.author.user_id) {
                    let user = {...users[record.author.user_id]}
                    userName = user ? getUserFullName(user) : null
                } else if (record.author.type === 'bot' && record.author.bot_id) {
                    const bot = bots[String(record.author.bot_id)]
                    if (bot) userName = bot
                    else userName = 'Bot ID ' + record.author.bot_id
                }

                recordClasses += " align-left"
                recordClasses += (record.type === 'note' ? " note" : " out");

                // fix last for out messages
                isLast = isLast || record.author.type !== nextRecord.author.type || record.author.manager_id !== nextRecord.author.manager_id || record.author.bot_id !== nextRecord.author.bot_id;
                // if (isLast) console.log('isLast', record.message, record.author, prevRecord && prevRecord.message, prevRecord && prevRecord.author, nextRecord.message, nextRecord.author)

                records.push(<div key={record.id} className={`${classes.message} align-right`}>
                    {renderMessage(record, recordClasses)}
                    {isLast && userName ? <div className={`${classes.name} padding-bottom-xs`}>{userName}</div> : null}
                </div>)
            }
        }

        return records
    }

    function renderPlatformIcon(contactType) {
        const wcn = 'margin-left-xs float-right'
        const platformIconsMap = {
            'tg_id': <Fa icon="telegram" set="brands" wcn={wcn} color="#0088cc"/>,
            'vk_id': <Fa icon="vk" set="brands" wcn={wcn} color="#4c75a3"/>,
            'ig_id': <Fa icon="instagram" set="brands" wcn={wcn} color="#e1306c"/>,
            'fb_id': <Fa icon="facebook" set="brands" wcn={wcn} color="#3b5998"/>,
            'ok_id': <Fa icon="odnoklassniki" set="brands" wcn={wcn} color="#ed812b"/>,
            'av_id': <Fa icon="avito" set="brands" wcn={wcn} color="#ff8c00"/>,
            'wa_id': <Fa icon="whatsapp" set="brands" wcn={wcn} color="#25d366"/>,
            'vb_id': <Fa icon="viber" set="brands" wcn={wcn} color="#665cac"/>,
            'phone': <Fa icon="phone" set="regular" wcn={wcn}/>,
            'email': <Fa icon="envelope" set="regular" wcn={wcn}/>,
        }
        return platformIconsMap[contactType] || null
    }

    const projectLoaded = project_item && project_item.id;
    const isEnabled = deepGet(project_item, 'params.dialogs.is_on')
    const enableSection = () => {
        let dialogParams = project_item.params.dialogs ? {...project_item.params.dialogs} : {}
        dialogParams['is_on'] = true
        dispatch({type: 'updateProject', admin, data: {id: project_item.id, params: {dialogs: dialogParams}}});
    }

    return (
        <Layout className="site-layout site-layout-background">
            <div className={app_classes.app_wrapper}>
                <div className={`${classes.leftPanel} ${id ? classes.opened : classes.closed}`}>
                    <LeftPanel adaptive={false}/>
                </div>

                <Content
                    className={`site-layout-background flex full-height ${id || !isEnabled ? classes.opened : classes.closed}`}>
                    <div className={`${classes.listWrap} flex flex-column${isEnabled ? '' : ' hide'}`}>
                        <List.Item>
                            <Input.Search
                                className={classes.search}
                                placeholder={t(section + '.list.search.placeholder')}
                                size="large"
                                style={{width: 200}}
                                onSearch={searchDialogs}
                                // onChange={value => console.log(value)}
                            />
                        </List.Item>

                        <div
                            className="full-height"
                            // className={classes.list}
                            // style={{maxHeight: 120}}
                        >
                            <Scrollbar
                                onScroll={onListScroll}
                                ref={listScrollRef}
                            >
                                <List
                                    locale={{
                                        emptyText: <>
                                            <div><CoffeeOutlined className={classes.emptyIcon}/></div>
                                            <div>{t(section + '.list.empty')}</div>
                                        </>
                                    }}
                                >{list.length ? list.map((record, index) => {
                                    let user = {...users[record.user_id]}
                                    if (!user.photo_url) user.photo_url = images.universe.sections.user

                                    // TODO: add to backend auto deleting messages on contact or user delete
                                    if (user.deleted_at) return null;

                                    let itemClasses = `${classes.listItem} cursor-pointer`
                                    if (Number(record.id) === id) itemClasses += ` ${classes.active}`
                                    else if (!deepGet(record, ['status'])) itemClasses += ` ${classes.unread}`

                                    const botName = bots[String(record.contact.bot_id)] || 'Bot ' + record.contact.bot_id;

                                    let messageText = (stripHtmlTags(record.message || '')).substring(0, 30)
                                    if (!messageText) {
                                        if (typeof record.const === 'string' && record.const) {
                                            messageText = t(record.const)
                                        } else {
                                            messageText = t(record.event.type.split('/').join('.') + '.title')
                                        }
                                    }

                                    return (
                                        <a
                                            key={record.id}
                                            href={`${routes.project_list}/${project_item.id}/${section}/${record.id}`}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                openDialog(record.id)
                                            }}
                                        >
                                            <List.Item className={itemClasses}>
                                                <List.Item.Meta
                                                    title={<div>
                                                        {renderDateTime(record, `float-right margin-left-xs ${classes.listDate}`)}
                                                        <Text
                                                            type={record.contact.is_allowed === false ? "danger" : null}
                                                            className="nowrap"
                                                        >{getUserFullName(user)}</Text>
                                                    </div>}
                                                    avatar={<Avatar size={48} src={user.photo_url}/>}
                                                    description={<span>
                                                        {renderPlatformIcon(record.contact.type)}
                                                        {botName}: {messageText}
                                                    </span>}
                                                />
                                            </List.Item>
                                        </a>
                                    )
                                }) : null}</List>
                            </Scrollbar>
                        </div>
                    </div>


                    <div className={`${classes.messagesWrap}`}>
                        <div className="flex flex-column">
                            {id > 0 ? <div
                                className={`${classes.header} flex bg-white padding-top-ps padding-bottom-ps padding-left-sm padding-right-sm`}
                            >
                                <Button
                                    icon={<ArrowLeftOutlined/>}
                                    onClick={closeDialog}
                                    type="text"
                                    className="float-left"
                                />

                                <Title level={3} className="flex-grow-1 text-center">
                                    {currentUser ? getUserFullName({...currentUser}) : null}
                                </Title>

                                {(messages.length && messages[0].contact.type === 'vk_id') ? <Tooltip
                                    title={t(section + '.item.profile.dialog')}
                                    placement="bottomRight"
                                >
                                    <Button
                                        icon={<ExportOutlined/>}
                                        href={platformDialogUrl(messages[0])}
                                        className="margin-right-xs"
                                        target="_blank"
                                        type="text"
                                    />
                                </Tooltip> : null}

                                {currentUser ?
                                    <Tooltip title={t(section + '.item.profile.open')} placement="bottomRight">
                                        <Button
                                            icon={<UserOutlined/>}
                                            href={userUrl(currentUserId)}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                navigate(userUrl(currentUserId))
                                            }}
                                            target="_blank"
                                            type="text"
                                        />
                                    </Tooltip> : null}

                            </div> : null}

                            <Scrollbar
                                onScroll={onMessagesScroll}
                                className={id > 0 && isEnabled ? 'flex-grow-1' : 'hide'}
                                ref={messagesScrollRef}
                            >
                                <div id="messages" className={`${classes.messages}`}>
                                    {renderMessages()}
                                </div>
                            </Scrollbar>

                            {id > 0 || !isEnabled ? null : <Result
                                status="404"
                                title={t(section + '.item.empty.title')}
                                className="margin-top-lg"
                                subTitle={<>
                                    <div className="margin-bottom-ps">{t(section + '.item.empty.desc')}</div>
                                    <div>
                                        <a
                                            className="text-secondary text-underline"
                                            href={`${routes.project_list}/${project_id}/edit#dialogs`}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                navigate(`${routes.project_list}/${project_id}/edit#dialogs`)
                                            }}
                                        >
                                            {t(section + '.item.empty.settings')}
                                        </a>
                                    </div>
                                </>}
                            />}

                            {projectLoaded ? (isEnabled ? null : <div className={classes.enable}>
                                <Scrollbar className="result">
                                    <Result
                                        title={t(section + '.item.off.title')}
                                        className="margin-top-lg margin-bottom-lg"
                                        subTitle={<div
                                            dangerouslySetInnerHTML={createMarkup(t(section + '.item.off.desc'))}
                                        />}
                                        extra={<Button type="primary" onClick={enableSection}>
                                            {t(section + '.item.off.on')}
                                        </Button>}
                                    />
                                </Scrollbar>
                            </div>) : <Preloader/>}

                            <Form
                                form={form}
                                type="horizontal"
                                onFinish={sendMessage}
                                className={(id > 0 && !forbidden) ? 'block' : 'hide'}
                            >
                                <div className={`flex ${classes.form}`}>
                                    <div className={`${classes.textarea}`}>
                                        <Form.Item
                                            className="margin-none"
                                            name={['record', 'message']}
                                        >
                                            <Input.TextArea
                                                ref={textareaRef}
                                                placeholder={t(section + '.form.message.placeholder')}
                                                // showCount={true}
                                                maxLength={4000}
                                                autoSize={{minRows: 1, maxRows: 20}}
                                                onKeyDown={e => {
                                                    submitFormByHotkeys(e, form)
                                                }}
                                            />
                                        </Form.Item>
                                    </div>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        icon={<SendOutlined/>}
                                        className={`${classes.submit}`}
                                        title={t(section + '.form.submit.label')}
                                    />
                                </div>
                                <div className={classes.paddingPsHorizontal}>
                                    <CheckboxField
                                        t={t}
                                        section={section}
                                        name="spec.handle_vars"
                                        label={t(section + '.form.handle_vars.label')}
                                        tooltip=''
                                    />
                                </div>
                            </Form>

                            {forbidden ? <Alert
                                type="warning"
                                className="padding-top-ps padding-bottom-ps padding-left-sm padding-right-sm"
                                message={t(section + '.status.stop')}
                            /> : null}
                        </div>
                    </div>

                    {/*{id > 0 ? <List className={`${classes.drawer}`}/> : null}*/}
                </Content>
            </div>

            <SimpleModal
                isVisible={modalPhotoUrl}
                setVisible={setModalPhotoUrl}
                actionLabel="ok"
                // title={t(section + '.item.attachment.preview')}
                title={null}
                showFooter={false}
                className={classes.modal}
                width={1500}
            >
                <div className="flex flex-column flex-center">
                    <div className="flex flex-row flex-center">
                        <a href={modalPhotoUrl} target="_blank" rel="noopener noreferrer" className="nowrap">
                            <img src={modalPhotoUrl} alt="Photo Preview" className="border-radius-md"/>
                        </a>
                    </div>
                </div>
            </SimpleModal>
        </Layout>
    )
}
export default DialogList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link