import {put, takeEvery} from 'redux-saga/effects'
import {
    setProjectItem,
    patchProjectItem,
    fillProjectList,
    addProjectToList,
    removeProjectFromList,
    setJustCreated,
    fillSharedProjectList,
    patchProjectInList,
} from '../reducers/ProjectReducer'
import {setUserStoreParam} from "../reducers/UserReducer";
import {backend_api} from "../../config/config";
import {request} from "../../library/api";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {setCorrect} from "../reducers/AdminReducer";
import {setOrderFormLoading} from "../reducers/OrderReducer";
import {notice} from "../../library/notice";
import {l} from "../../library/locale";

const proj_base = backend_api.project;

function* getProjectList(arg) {
    try {
        // const response = yield axios({ method: 'get', url: proj_base, headers: arg.admin.header_authorized });
        const filters = arg.filters ? arg.filters : false;
        const response = yield request(arg.admin.token, proj_base, 'get', filters);

        if (response.data.ok) yield put(fillProjectList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect())
        apiErrorNotice(error)
    }
}


function* getUserProjectList(arg) {
    try {
        // const response = yield axios({ method: 'get', url: proj_base, headers: arg.admin.header_authorized });
        const filters = arg.filters ? arg.filters : false;
        const response = yield request(arg.admin.token, proj_base + '/list/user', 'get', filters);

        if (response.data.ok) yield put(setUserStoreParam({projects: response.data.result}))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect())
        apiErrorNotice(error)
    }
}

function* getSharedProjectList(arg) {
    try {
        // const response = yield axios({ method: 'get', url: proj_base, headers: arg.admin.header_authorized });
        const filters = arg.filters ? arg.filters : {};
        filters.is_shared = true;
        const response = yield request(arg.admin.token, proj_base, 'get', filters);

        if (response.data.ok) yield put(fillSharedProjectList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect())
        apiErrorNotice(error)
    }
}

function* getProjectItem(arg) {
    try {
        const response = yield request(arg.admin.token, proj_base + '/' + arg.id, 'get', arg.data);
        if (response.data.ok) yield put(setProjectItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if (error.response.status === 401) yield put(setCorrect())
        if (error.response.status === 403) yield put(setProjectItem({id: 0}))  // redirect to existing project
        apiErrorNotice(error)
    }
}

function* createProject(arg) {      // эта же сага используется для duplicate project
    try {
        const response = yield request(arg.admin.token, proj_base, 'post', arg.data);
        if (response.data.ok) {
            const result = response.data.result
            yield put(addProjectToList(result));
            // yield put(setJustCreated(result.id));
        }
        else apiFalseNotice(response)
    } catch (error) {
        // if(error.response.status === 401) yield put(setCorrect())
        apiErrorNotice(error)
    }
}

function* updateProject(arg) {
    try {
        const response = yield request(arg.admin.token, proj_base, 'patch', arg.data);
        if (response.data.ok) yield put(patchProjectInList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if(error.response.status === 401) yield put(setCorrect(false))
        apiErrorNotice(error)
    }
}

function* restoreProject(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, proj_base, 'patch', data);
        if (response.data.ok) yield put(removeProjectFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteProject(arg) {
    try {
        const response = yield request(arg.admin.token, proj_base + '/' + arg.id, 'delete');
        if (response.data.ok) yield put(removeProjectFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        if(error.response.status === 401) yield put(setCorrect(false))
        apiErrorNotice(error)
    }
}

function* applyProjectPromo(arg) {
    try {
        yield put(setOrderFormLoading({promo: false}))
        const response = yield request(arg.admin.token, proj_base + '/promo', 'patch', arg.data);
        if (response.data.ok) {
            yield put(patchProjectItem(response.data.result))
            yield put(setOrderFormLoading({
                balance: false,
                tariff: false,
                promo: false,
            }))
            notice.success(l('project.tariff.calc.pay.promo.success'))
        }
        else apiFalseNotice(response)
    } catch (error) {
        // if(error.response.status === 401) yield put(setCorrect(false))
        apiErrorNotice(error)
    }
}

export function* projectSaga() {
    yield takeEvery('getProjectList', getProjectList);
    yield takeEvery('getSharedProjectList', getSharedProjectList);
    yield takeEvery('getUserProjectList', getUserProjectList);
    yield takeEvery('getProjectItem', getProjectItem);
    yield takeEvery('createProject', createProject);
    yield takeEvery('updateProject', updateProject);
    yield takeEvery('restoreProject', restoreProject);
    yield takeEvery('deleteProject', deleteProject);
    yield takeEvery('applyProjectPromo', applyProjectPromo);
}

