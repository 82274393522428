import React, {useContext} from 'react'
import classes from '../../../../../Styles/ListStyles.module.scss'
import Fa from "../../../../../../../../../../components/Icon/Fa";
import {useTranslation} from 'react-i18next';
import FlowContext from "../../../../../../../../FlowContext";
import {dotenv} from "../../../../../../../../../../config/config";
import {useParams} from "react-router-dom";

const List = ({ containers }) => {
    const {addNodeEvent} = useContext(FlowContext);

    // path params
    const params = useParams()
    const project_id = Number(params.project_id);

    const addNewEvent = (type) => {
        addNodeEvent(type);
    }

    const {t} = useTranslation();
    const renderEventItem = (title, icon, index, type) => {
        return (
            <div className={classes.row_list} key={`blocklist_${index}`} onClick={()=>{addNewEvent(type)}}>
                <div className={classes.row_icon}><Fa icon={icon} options={'lg'}/></div>
                <div className={classes.row_title}>{title}</div>
            </div>
        )
    }
    
    const getTitle = (title, type) => {
        if(title === undefined || title === null) return t(`${type.split('/').join('.')}.title`);
        if(title) return title;
        return null;
    }

    const renderContainerList = () => {
        const rows = [];
        containers.forEach((container, index) => {
            if (container.system && project_id !== dotenv.main_project) return
            if (container.hidden) return

            const title = getTitle(container.title, container.type)
            rows.push(renderEventItem(title, container.icon, index, container.type));
        })
        return rows;
    }
    
    return (
        <div className={`containers-list-wrapper ${classes.wrapper_lists}`}>
            { renderContainerList() }
        </div>
    )
}

export default List