import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {routes} from "../../../config/config";
import {Layout} from "antd";

import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {ListHeader} from "../../../components/List/Header/ListHeader";
import {UtmListTable} from "./UtmListTable";
import {createObjectFromObjectsArray, objectLength, ucFirst} from "../../../library/functions";
import {utmFields} from "../../../components/Layout/CustomHeader";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const UtmList = () => {
    // init section name
    const section = 'utm'
    const {t} = useTranslation();

    // init hook functions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // store and state
    const {admin, project, utm, page, field, product} = useSelector(store => store)
    const [filters, setFilters] = useState({})

    const pages = createObjectFromObjectsArray(page.short.list)
    const products = createObjectFromObjectsArray(product.short.list)
    const groups_array = field.list.filter(item => item.system_field_type === 'list')
    const groups = createObjectFromObjectsArray(groups_array)

    // init universal vars
    const project_item = project.item
    const list = utm.list
    const rowsTotal = utm.count || 0
    const currentPage = utm.page || 1
    const pageSize = utm.pageSize || 10
    const sorter = utm.sorter

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            let ordering = '-id'
            if (sorter && objectLength(sorter)) {
                let prefix = sorter.order === 'ascend' ? '' : '-'
                ordering = prefix + sorter.field
            }

            let filters_result = {
                project_id: project_item.id,
                // profile_types: ['user', 'bot', 'chat', 'channel'],
                ordering: ordering,
                page_number: currentPage,
                page_size: pageSize,
            }

            if (objectLength(filters)) filters_result = {...filters_result, ...filters}

            dispatch({type: 'get' + ucFirst(section) + 'List', admin, filters: filters_result})
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, sorter, currentPage, pageSize])

    useEffect(() => {
        if (admin.authorized && project_item.id) {
            // for filters
            dispatch({type: 'getPageShortList', admin, filters: {project_id: project_item.id, ordering: '-id'}})
            dispatch({type: 'getProductShortList', admin, filters: {project_id: project_item.id, ordering: '-id'}})
            dispatch({
                type: 'getFieldList', admin, filters: {
                    project_id: project_item.id,
                    ordering: '-id',
                    system_field_types: ['list']
                }
            })
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    const searchHandler = (value) => {
        let filters_result = {value: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordEdit = () => {
        navigate(`${routes.project_list}/${project_item.id}/${section}/edit/0`)
    }

    const filterFields = [
        [
            ...utmFields[0],
            {
                // move to end (date fields) when funnel_ids will be ready
                name: 'tail',
                type: 'text',
                label: 'UTM Tail',
                placeholder: 'custom=abc&param=123',
                width: 24
            },
            {
                name: 'field_ids',
                type: 'tags',
                localized: false,
                values: groups,
                section: section,
                placeholder: t('common.placeholder.tags')
            },
            {
                name: 'page_ids',
                type: 'tags',
                localized: false,
                values: pages,
                section: section,
                placeholder: t('common.placeholder.tags')
            },
            {
                name: 'product_ids',
                type: 'tags',
                localized: false,
                values: products,
                section: section,
                placeholder: t('common.placeholder.tags')
            },
            // {
            //     name: 'funnel_ids',
            //     type: 'tags',
            //     localized: false,
            //     values: funnels,
            //     section: section,
            //     placeholder: t('common.placeholder.tags')
            // },
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordEdit}
                    searchPlaceholder={t(section + '.list.search.placeholder')}
                />
                <Content className="page-container site-layout-background">
                    <UtmListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        rowsTotal={rowsTotal}
                    />
                </Content>
            </Layout>
        </AppWrapper>
    )
}
export default UtmList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link