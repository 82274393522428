import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from "antd";
import {CheckOutlined, DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {FormFields} from "../../../../../../../components/Form/Field/FormFields";
import {useHotkeys} from "react-hotkeys-hook";
import {composeButtonCard} from "./Keyboard";
import {textLengthInBytes} from "../../../../../../../library/functions";

const buttonTypes = [
    'text',
    'url',
    'app',
    'request',
    // 'pay',
    // 'game',
]

const KeyboardModal = ({cards, chosenCard, setChosenCard, onModalOk = null, onDelete = null}) => {
    const {t} = useTranslation();
    const [formValues, setFormValues] = useState(null);
    const [textLen, setTextLen] = useState(null);

    useEffect(() => {
        if (chosenCard) {
            let card;

            if (chosenCard.id) card = cards[chosenCard.rowIndex][chosenCard.index]
            else card = composeButtonCard()

            setFormValues(card)
            form.setFieldsValue(card)

            let len = textLengthInBytes(card.label)
            setTextLen(len)
        } else {
            setFormValues({})
        }
    }, [cards, chosenCard]);

    const [form] = Form.useForm()
    let formFields = {
        text: [
            {
                name: "type",
                default: "text",
                type: "hidden",
            },
            {
                name: "label",
                type: "text",
                // max_length: 27,
                showCount: true,
                required: true,
                autoFocus: true, // TODO add real auto focus on modal show
            },
            {
                name: "color",
                type: "style",
                values: ['primary', 'default', 'success', 'danger'],
                default: 'primary',
            },
            {
                name: "payload",
                type: "textarea",
            },
            // {
            //     name: "keep_original",
            //     type: "switcher",
            // },
        ],
        url: [
            {
                name: "type",
                default: "url",
                type: "hidden",
            },
            {
                name: "label",
                type: "text",
                showCount: true,
                required: true,
            },
            {
                name: "spec.url",
                type: "text",
                placeholder: true,
                required: true,
            },
            {
                name: "warning.url",
                type: "description",
                wrapperClassName: 'color-danger',
            },
            {
                name: "spec.turn_off_event",
                type: "switcher",
            },
        ],
        app: [
            {
                name: "type",
                default: "url",
                type: "hidden",
            },
            {
                name: "label",
                type: "text",
                showCount: true,
                required: true,
            },
            {
                name: "spec.url",
                type: "text",
                placeholder: true,
                required: true,
            },
            // {
            //     name: "warning.app",
            //     type: "description",
            //     wrapperClassName: 'color-danger',
            // },
        ],
        request: [
            {
                name: "type",
                default: "request",
                type: "hidden",
            },
            {
                name: "label",
                type: "text",
                showCount: true,
                required: true,
            },
            {
                name: "spec.object",
                type: "menu",
                placeholder: '',
                localized: true,
                values: ['contact', 'user', 'chat', 'poll', 'location'],
                container: '.ant-modal-body',
            },
            {
                name: "spec.params",
                type: "textarea",
                default: 'true',
            },
            {
                name: "warning.request",
                type: "description",
                wrapperClassName: 'color-danger',
            },
        ],
    }

    if (chosenCard && chosenCard.id) {
        formFields.url[4].type = 'hidden';
    }

    useHotkeys('ctrl+enter,command+enter,enter', e => {
        if (formValues) handleOk()
    }, [form, formValues]);

    useHotkeys('esc', e => {
        if (formValues) handleCancel()
    }, [form, formValues]);

    if (!formValues || !chosenCard) return null;
    const buttonType = formValues.type;

    const handleOk = () => {
        form.submit()
    }

    const handleCancel = () => {
        setChosenCard(null)
    }

    const onSubmit = () => {
        const values = form.getFieldsValue()
        if (onModalOk && formValues) onModalOk(values)
        handleCancel()
    }

    const onChange = () => {
        const values = form.getFieldsValue()
        let len = textLengthInBytes(values.label)
        setTextLen(len)
    }

    return (
        <Modal
            id="buttons-edit-modal"
            className="modal-clean"
            title={t('graph.flow.keyboard.modal.title') + (chosenCard.id ? ` # ${chosenCard.id}` : '')}
            open={!!chosenCard}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="user"
                    type="text"
                    className={chosenCard.id ? 'float-left inverted' : 'hide'}
                    onClick={onDelete}
                    icon={<DeleteOutlined/>}>
                    <span className="hidden-xs-important">{t('common.action.delete')}</span>
                </Button>,
                <Button
                    key="modal-back"
                    type="text"
                    onClick={handleCancel}
                    className="inverted"
                >{t('common.action.cancel')}</Button>,
                <Button
                    key="modal-submit"
                    type="primary"
                    onClick={handleOk}
                    icon={<CheckOutlined/>}
                >{t('common.action.apply')}</Button>
            ]}
        >

            <div className="tabs-buttons margin-bottom-xs">
                {chosenCard.id ? null : buttonTypes.map((type) => {
                    return <div
                        key={type}
                        onClick={()=>{
                            const newFormValues = {...formValues, type: type}
                            form.setFieldsValue(newFormValues)
                            setFormValues(newFormValues)
                        }}
                        className={`ant-tabs-tab ${buttonType === type? 'ant-tabs-tab-active':''}`}
                    >
                        <div className="ant-tabs-tab-btn">{t('graph.flow.keyboard.modal.tab.'+type)}</div>
                    </div>
                })}

                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onSubmit}
                    onFinishFailed={null}
                    onChange={onChange}
                    className=""
                    name="settings"
                    layout="vertical"
                >
                    <FormFields t={t} section="graph.flow.keyboard.modal" fields={formFields[buttonType]}/>
                </Form>
            </div>

        </Modal>
    );
};

export default KeyboardModal;

