import React from 'react'
import classes from './AppWrapper.module.scss'
import LeftPanel from "./LeftPanel/LeftPanel";
import HelpPopup from "./Help/HelpPopup";
import Scrollbar from "react-scrollbars-custom";
import {dotenv} from "../../../config/config";

const AppWrapper = (
    {
        children,
        className = '',
        onScroll = null,
        adaptivePanel = true,
        showHelp = true,
        scrollRef = null,
    }
) => {
    const handleScroll = (values) => {
        // Call the onScroll function passed as a prop
        if (onScroll && typeof onScroll === 'function') {
            onScroll(values);
        }
    };

    return (
        <div id={"app-wrapper"} className={classes.app_wrapper}>
            <LeftPanel adaptive={adaptivePanel}/>
            <Scrollbar ref={scrollRef} className={className} onScroll={handleScroll}>
                <div id="app-page-wrapper" className={classes.app_page}>
                    {children}
                </div>
            </Scrollbar>
            {dotenv.is_gamificated && showHelp ? <HelpPopup/> : null}
        </div>
    )
}

export default AppWrapper