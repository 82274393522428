import React, {useEffect, useRef, useState} from 'react';
import {l} from "../../../library/locale";
import classes from './FormFields.module.scss'
import {isMobile} from "../../../library/isMobile";
import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Select,
    Tooltip,
    TimePicker,
    DatePicker,
    Divider,
    Space,
    Radio,
    Typography,
    Row,
    Col,
    Tag,
} from "antd";
import {
    formRequiredRules,
    createMarkup,
    deepGet,
    inArray,
    isArr,
    ucFirst,
    objectLength,
    filterSelectOptionsByContent,
    filterSelectOptionsByLabel,
    replaceVarsInObj,
    replaceVars,
    pasteOnlyText,
} from "../../../library/functions";
import {FormItemWrapper} from "../FormItemWrapper";
import {useTranslation} from "react-i18next";
import {LocaledOptions} from "./LocaledOptions";
import {ColorOptions, IconsOptions} from "../../Icon/Catalog";
import {LabelSwitch} from "./LabelSwitch";
import {AvatarUpload} from "./AvatarUpload";
import {ImagesField} from "./ImagesField";
import {UploadField} from "./UploadField";
import {AiOutlineSetting} from "react-icons/ai";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    ExperimentOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {OptionsFromObject} from "./OptionsFromObject";
import {moment_formats} from "../../../config/config";
import {copyValueToClipboard} from "../../../library/clipboard";
import {LabelPlatformsIcons} from "./PlatformIcons";
import {notice} from "../../../library/notice";
import TinyMCE from "../Editor/TinyMCE";
import CodeEditor from "../Editor/CodeEditor";
import moment from "moment";
import {FormItemLabel} from "../FormItemLabel";
import {SimpleModal} from "../../Modal/SimpleModal";
import {askChatGpt} from "../../../library/api";
import {NoForm} from "../NoForm";
import {NavTreeField} from "./NavTreeField";
import {SectorsField} from "./SectorsField";

const {Option} = Select;
const {TextArea} = Input;
const {Title} = Typography;

// TODO: https://github.com/react-simple-code-editor/react-simple-code-editor

// =========== Library ===========

export const SetFieldInfo = (
    t,
    effectType,
    name,
    info,
    infoType = 'label',
    vars = null
) => {
    let result = null;
    let is_placeholder = infoType === 'placeholder';

    if (infoType === 'placeholder' && info === true) {
        info = null
        infoType = 'label'
    }

    if (typeof info !== 'string' && !info) {
        let locale_params = {interpolation: {escapeValue: false}};
        result = t(effectType + '.form.' + name + '.' + infoType, locale_params);
        if (vars && result) result = replaceVars(result, vars);
        if (!is_placeholder) result = <span dangerouslySetInnerHTML={createMarkup(result)}/>

    } else if (info !== '') {
        result = info;
    }

    return result;
}

export const fieldsHotkeys = (e) => {
    const metaKey = (e.ctrlKey || e.metaKey) && !e.altKey && !e.shiftKey;
    if (metaKey) {
        if (e.keyCode === 13 || e.keyCode === 83) {
            e.preventDefault();
            e.stopPropagation();
            window.getSelection().removeAllRanges();
            e.target.blur();
        }
    }
}

export const submitFormByHotkeys = (e, form, listenMetaKey = true) => {
    const metaKey = listenMetaKey ? ((e.ctrlKey || e.metaKey) && !e.altKey && !e.shiftKey) : true;
    // console.log('e', metaKey, e.keyCode, form.getFieldsValue())
    if (metaKey) {
        if (e.keyCode === 13) {
            e.preventDefault();
            // e.stopPropagation();
            // window.getSelection().removeAllRanges();
            // e.target.blur();
            form.submit();
        }
    }
}

export const getName = (name) => {
    if (!name) return null;
    if (isArr(name)) return name;
    const nameArray = name.split('.')
    return nameArray.length > 1 ? nameArray : name;
}

export const CheckFieldDependsOn = (field, spec) => {
    let depend_result = true;
    if (!field || !('depends_on' in field)) return depend_result;

    for (let [dependField, dependValue] of Object.entries(field.depends_on)) {
        // if (dependField === 'path') console.log('Depends On:', typeof dependValue, field.name, !!specState[dependField], dependField, dependValue)
        // let specValue;
        // if (dependField in spec) specValue = spec[dependField];
        let specValue = deepGet(spec, dependField, null);

        if (typeof dependValue === 'boolean') {
            if (!!specValue !== dependValue) {
                depend_result = false;
                break;
            }
        } else if (typeof dependValue === 'object') {
            if (!inArray(specValue, dependValue)) {
                depend_result = false;
                break;
            }
        } else {
            if (specValue !== dependValue) {
                depend_result = false;
                break;
            }
        }
    }

    return depend_result;
}

// =========== Form Handler ===========

export const FormFields = (
    {
        t,
        form = null,
        admin = null,
        project_id = 0,
        formValues = {},
        section = 'common',
        fields = '',
        vars = null,
    }
) => {
    let fieldList = []

    const functions = {
        "text": TextField,
        "warning": WarningField,
        "textarea": TextAreaField,
        "code": CodeEditorField,
        "editor": EditorField,
        "message": MessageField,
        "number": NumberField,
        "hidden": HiddenField,
        "menu": MenuField,
        "tags": TagsField,
        "icon": IconField,
        "tw_color": TwColorField,
        "avatar": AvatarField,
        "images": ImagesField,
        "upload": UploadField,
        "folder": FolderField,
        "game": GameField,
        "style": StyleField,
        "switcher": SwitchField,
        "checkbox": CheckboxField,
        "params": ParamsField,
        "files": FilesField,
        "fields": FieldsField,
        "products": ProductsField,
        "password": PasswordField,
        "heading": HeadingField,
        "description": DescriptionField,
        "instructions": InstructionsField,
        "datetime": DateTimeField,
        "date-range": DateRangeField,
        "schedule": ScheduleField,
        "ai": AIAssistantField,
        'sectors': SectorsField,
        'nav': NavTreeField,
    }

    let i = 0;

    for (const fieldInit of fields) {
        i++;

        let field = {t, admin, project_id, form, formValues, ...fieldInit}

        // optional replace vars in description
        if (vars) replaceVarsInObj(field, vars, false);

        // check visibility after vars replacement
        if (field.isVisible === false || field.isVisible === '0') continue;

        // Add translation function to field
        if (field.section === undefined) field['section'] = section
        if (field.type === 'unset') continue;
        if (functions[field.type] === undefined) {
            notice.error('Field type is not supported: ' + field.type)
            console.log('This field type is not supported:', field.type)
            return
        }

        // set field component
        const fieldComponent = functions[field.type](field)

        let desc = SetFieldInfo(t, field.localeRoot || field.section, field.name, field.desc, 'desc', vars)
        if (field.desc !== undefined) desc = field.desc
        if (inArray(field.type, ['hidden', 'checkbox'])) desc = null

        let className = 'field-type-' + field.type + (field.wrapperClassName ? ' ' + field.wrapperClassName : '')
        const depend_result = CheckFieldDependsOn(field, formValues);
        if (!depend_result) className += ' hide'

        fieldList.push(
            <FormItemWrapper
                key={'item-form-field-' + field.name + i}
                desc={desc}
                className={className}
            >
                {fieldComponent}
            </FormItemWrapper>
        )
    }

    return fieldList

    // // attempt of adding field columns:
    // for (const fieldInit of fields) {
    //         i++;
    //
    //         const renderField = (fieldData) => {
    //             let field = {t, admin, project_id, form, formValues, ...fieldData}
    //
    //             // optional replace vars in description
    //             if (vars) replaceVarsInObj(field, vars, false);
    //
    //             // check visibility after vars replacement
    //             if (field.isVisible === false || field.isVisible === '0') return null;
    //
    //             // Add translation function to field
    //             if (field.section === undefined) field['section'] = section
    //             if (functions[field.type] === undefined) {
    //                 notice.error('Field type is not supported: ' + field.type)
    //                 console.log('This field type is not supported:', field.type)
    //                 return
    //             }
    //
    //             // set field component
    //             const fieldComponent = functions[field.type](field)
    //
    //             let desc = SetFieldInfo(t, field.section, field.name, field.desc, 'desc', vars)
    //             if (field.desc !== undefined) desc = field.desc
    //             if (inArray(field.type, ['hidden', 'checkbox'])) desc = null
    //
    //             let className = 'field-type-' + field.type + (field.wrapperClassName ? ' ' + field.wrapperClassName : '')
    //             const depend_result = CheckFieldDependsOn(field, formValues);
    //             if (!depend_result) className += ' hide'
    //
    //             return (
    //                 <FormItemWrapper
    //                     key={'item-form-field-' + field.name + i}
    //                     desc={desc}
    //                     className={className}
    //                 >
    //                     {fieldComponent}
    //                 </FormItemWrapper>
    //             )
    //         }
    //
    //         if (isArr(fieldInit)) {
    //             const width = 24 / fieldInit.length
    //             let fieldsResult = []
    //
    //             for (const fieldData of fieldInit) {
    //                 const fieldResult = renderField(fieldData);
    //                 if (fieldResult == null) continue
    //
    //                 fieldsResult.push(
    //                     <Col key={'item-form-field-' + fieldData.name + i} xs={24} sm={width}>
    //                         {fieldResult}
    //                     </Col>
    //                 )
    //             }
    //
    //             if (fieldsResult) fieldList.push(<Row gutter={24}>
    //                 {fieldsResult}
    //             </Row>)
    //
    //         } else {
    //             const fieldResult = renderField(fieldInit);
    //             if (fieldResult !== null) fieldList.push(fieldResult)
    //         }
    //     }
}

// =========== Form Fields ===========

export const DescriptionField = (
    {
        t,
        name = 'field',
        section = 'common',
        label = null,
    }
) => {
    return (
        <Form.Item label={SetFieldInfo(t, section, name, label, 'label')} className="hide-last-child">
            <div/>
        </Form.Item>
    );
}

export const InstructionsField = (
    {
        t,
        name = 'field',
        section = 'common',
        defaultValue = '',
        label = null,
        level = 3
    }
) => {
    // console.log('defaultValue', defaultValue)
    return <>
        <Title level={level}>{SetFieldInfo(t, section, name, label, 'label')}</Title>
        <div className="instructions-field-inner" dangerouslySetInnerHTML={createMarkup(defaultValue)}/>
    </>
}

export const WarningField = (
    {
        t,
        name = 'field',
        section = 'common',
        label = null,
        status = false,
    }
) => {
    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, label, 'label')}
            className={`hide-last-child${status ? '' : ' zero-px-height'}`}
        >
            <div/>
        </Form.Item>
    );
}

export const HeadingField = (
    {
        t,
        name = 'field',
        section = 'common',
        label = null,
        level = 3,
    }
) => {
    return (
        <Title level={level}>{SetFieldInfo(t, section, name, label, 'label')}</Title>
    );
}

export const TextField = (
    {
        t,
        name = 'field',
        section = 'common',
        size = 'middle',
        label = null,
        placeholder = null,
        readonly = false,
        required = false,
        max_length = 0,
        showCount = false,
        onBlur = () => {
        },
        onChange = () => {
        },
    }
) => {
    const rules = required ? formRequiredRules : null;

    return (
        <Form.Item
            className={size === 'small' ? 'ant-field-sm' : ''}
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={getName(name)}
            rules={rules}
        >
            {readonly ?
                <input
                    type="text"
                    className="ant-input"
                    readOnly={true}
                    onClick={copyValueToClipboard}
                /> :
                <Input
                    showCount={!!max_length || showCount}
                    maxLength={max_length ? max_length : null}
                    placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                    size={size}
                    autoComplete='off'
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={fieldsHotkeys}
                />}
        </Form.Item>
    );
}

export const PasswordField = (
    {
        t,
        name = 'field',
        section = 'common',
        size = 'middle',
        label = null,
        placeholder = null,
        required = false,
        max_length = 0,
        showCount = false,
        autoComplete = 'off',
        onBlur = () => {
        },
        onChange = () => {
        },
    }
) => {
    const rules = required ? formRequiredRules : null;

    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={getName(name)}
            rules={rules}
        >
            <Input.Password
                size={size}
                showCount={!!max_length || showCount}
                maxLength={max_length ? max_length : null}
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={fieldsHotkeys}
                autoComplete={autoComplete}
                data-lpignore={autoComplete === 'off' ? 'true' : 'false'}
            />
        </Form.Item>
    );
}

export const ParamsField = (
    {
        t,
        form = null,
        name = 'params',
        section = 'common',
        size = 'middle',
        label = null,
        placeholder = null,
        onBlur = null,
        onChange = null,
        onDelete = null,
        localePath = null, // 'graph.container.form.params'
        localeRoot = null, // 'graph.container',
        container = 'app-page-wrapper', // for select field rendering
    }
) => {
    // console.log('localePath', localePath, 'localeRoot', localeRoot)
    let localeBase = (localeRoot || section) + '.form.'  + name;
    if (localePath) localeBase = localePath;

    if (form && !onBlur && !onDelete) {
        onBlur = (values) => {
            // console.log('onBlur values', values)
        }
        onDelete = (values) => {
            // console.log('onDelete values', values)
        }
    }

    const l = (c) => t(localeBase + '.' + c);
    const l2 = (c) => t('graph.container.form.params.' + c);

    return (
        <Form.List name={name}>
            {(fields, {add, remove}) => (<>
                {fields.map(({key, name, ...restField}) => (
                    <div key={key} className="margin-bottom-sm">
                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={11}>
                                <Form.Item {...restField} name={[name, 'name']}>
                                    <Input placeholder={l2('param.placeholder')} onBlur={onBlur}/>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    // label={SetFieldInfo(section, name, label, 'label')}
                                    name={[name, 'type']}
                                >
                                    <Select
                                        onChange={onBlur}
                                        defaultValue={'text'}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                    >
                                        <Option value={'text'} key={'select-type-text'}>
                                            {l2('type.value.text')}
                                        </Option>
                                        <Option value={'float'} key={'select-type-float'}>
                                            {l2('type.value.float')}
                                        </Option>
                                        <Option value={'int'} key={'select-type-int'}>
                                            {l2('type.value.int')}
                                        </Option>
                                        <Option value={'bool'} key={'select-type-bool'}>
                                            {l2('type.value.bool')}
                                        </Option>
                                        <Option value={'array'} key={'select-type-array'}>
                                            {l2('type.value.array')}
                                        </Option>
                                        <Option value={'json'} key={'select-type-json'}>
                                            {l2('type.value.json')}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Button title={l('delete')} type="text" onClick={() => {
                                    remove(name)
                                    onDelete(name)
                                }} icon={<DeleteOutlined/>}/>
                            </Col>
                        </Row>
                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={24}>
                                <Form.Item {...restField} name={[name, 'value']}>
                                    {/*<Input placeholder={l('value.placeholder')}/>*/}
                                    <TextArea
                                        placeholder={l2('value.placeholder')}
                                        autoSize={{
                                            minRows: 1,
                                            maxRows: 9,
                                        }}
                                        onBlur={onBlur}
                                        onKeyDown={fieldsHotkeys}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ))}
                <Button type="text" onClick={() => {
                    add()
                    onBlur()
                }} icon={<PlusOutlined/>} className="padding-none-horizontal">
                    {l('add')}
                </Button>
            </>)}
        </Form.List>
    );
}

export const FilesField = (
    {
        t,
        form = null,
        name = 'params',
        section = 'common',
        size = 'middle',
        label = null,
        onBlur = null,
        onChange = null,
        onDelete = null,
        localePath = null, // 'graph.container.form.params'
        localeRoot = null, // 'graph.container',
    }
) => {
    // console.log('localePath', localePath, 'localeRoot', localeRoot)
    let localeBase = (localeRoot || section) + '.form.'  + name;
    if (localePath) localeBase = localePath;

    if (form && !onBlur && !onDelete) {
        onBlur = (values) => {
            // console.log('onBlur values', values)
        }
        onDelete = (values) => {
            // console.log('onDelete values', values)
        }
    }

    const l = (c) => t(localeBase + '.' + c);

    return (
        <Form.List name={name}>
            {(fields, {add, remove}) => (<>
                {fields.map(({key, name, ...restField}) => (
                    <div key={key} className="margin-bottom-sm">
                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={11}>
                                <Form.Item {...restField} name={[name, 'param']}>
                                    <Input placeholder={l('param.placeholder')} onBlur={onBlur}/>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item {...restField} name={[name, 'name']}>
                                    <Input placeholder={l('name.placeholder')} onBlur={onBlur}/>
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Button title={l('delete')} type="text" onClick={() => {
                                    remove(name)
                                    onDelete(name)
                                }} icon={<DeleteOutlined/>}/>
                            </Col>
                        </Row>
                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={24}>
                                <Form.Item {...restField} name={[name, 'value']}>
                                    <TextArea
                                        placeholder={l('value.placeholder')}
                                        autoSize={{
                                            minRows: 1,
                                            maxRows: 9,
                                        }}
                                        onBlur={onBlur}
                                        onKeyDown={fieldsHotkeys}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ))}
                <Button type="text" onClick={() => {
                    add()
                    onBlur()
                }} icon={<PlusOutlined/>} className="padding-none-horizontal">
                    {l('add')}
                </Button>
            </>)}
        </Form.List>
    );
}

export const FieldsField = (
    {
        t,
        name = 'field',
        section = 'fields',
        limit = 0,
        onBlur = null,
        onDelete = null,
    }
) => {
    const l = (c) => t('graph.container.form.' + section + '.' + c)
    const simple = (section in ['options', 'values', 'answers'])

    return (
        <Form.List name={name}>
            {(fields, {add, remove}) => (<>
                {fields.map(({key, name, ...restField}) => (
                    <Row key={key} gutter={10} className="margin-bottom-xp">
                        {simple ? null : <Col span={11}>
                            <Form.Item {...restField} name={[name, 'name']}>
                                <Input placeholder={l('name.placeholder')} onBlur={onBlur}/>
                            </Form.Item>
                        </Col>}
                        <Col span={simple ? 22 : 11}>
                            <Form.Item {...restField} name={[name, 'value']}>
                                {/*<Input placeholder={t('graph.container.form.params.value.placeholder')}/>*/}
                                <TextArea
                                    placeholder={l('value.placeholder')}
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 9,
                                    }}
                                    onBlur={onBlur}
                                    onKeyDown={fieldsHotkeys}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Button title={l('delete')} type="text" onClick={() => {
                                remove(name)
                                onDelete(name)
                            }} icon={<DeleteOutlined/>}/>
                        </Col>
                    </Row>
                ))}

                {!limit || fields.length < limit ?
                    <Button type="text" onClick={() => {
                        add()
                        onBlur()
                    }} icon={<PlusOutlined/>} className="padding-none-horizontal">
                        {l('add')}
                    </Button> : null
                }
            </>)}
        </Form.List>
    );
}

export const ScheduleField = (
    {
        t,
        label = null,
        name = 'field',
        section = 'fields',
        limit = 0,
        onBlur = null,
        onDelete = null,
        container = 'app-page-wrapper', // for select field rendering. Use 'effects-list-wrapper' for graphs
    }
) => {
    const fieldName = getName(name)
    const l = (c) => t('common.form.schedule.' + c)

    const generateNumberOptions = (start, end) => {
        let options = []
        for (let i = start; i <= end; i++) {
            const value = i < 10 ? '0' + i : i.toString()
            options.push(<Option key={value} value={value}>{value}</Option>)
        }
        return options
    }

    return (<>
        {label !== '' ? <FormItemLabel label={SetFieldInfo(t, section, name, label, 'label')}/> : null}
        <Form.List name={fieldName}>
            {(fields, {add, remove}) => (<>
                {fields.map(({key, name, ...restField}) => (
                    <div key={key} className="margin-bottom-sm">
                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={22}>
                                <Form.Item {...restField} name={[name, 'days']}>
                                    <Select
                                        mode="multiple"
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={l('days.placeholder')}
                                        optionFilterProp="children"
                                        filterOption={filterSelectOptionsByContent}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                        onBlur={onBlur}
                                    >
                                        {LocaledOptions({
                                            t,
                                            section: 'common',
                                            fieldName: 'schedule.days',
                                            items: ['0', '1', '2', '3', '4', '5', '6']
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Button title={l('delete')} type="text" onClick={() => {
                                    remove(name)
                                    onDelete(name)
                                }} icon={<DeleteOutlined/>}/>
                            </Col>
                        </Row>

                        <Row gutter={10} className="margin-bottom-xp">
                            <Col span={2} className="flex flex-center">{t('common.from')}</Col>
                            <Col span={5}>
                                <Form.Item {...restField} name={[name, 'from', 'hour']}>
                                    <Select
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'00'}
                                        optionFilterProp="children"
                                        filterOption={filterSelectOptionsByContent}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                        onBlur={onBlur}
                                        className="full-width"
                                    >{generateNumberOptions(0, 23)}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item {...restField} name={[name, 'from', 'min']}>
                                    <Select
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'00'}
                                        optionFilterProp="children"
                                        filterOption={filterSelectOptionsByContent}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                        onBlur={onBlur}
                                        className="full-width"
                                    >{generateNumberOptions(0, 59)}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="flex flex-center">{t('common.to')}</Col>
                            <Col span={5}>
                                <Form.Item {...restField} name={[name, 'to', 'hour']}>
                                    <Select
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'23'}
                                        optionFilterProp="children"
                                        filterOption={filterSelectOptionsByContent}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                        onBlur={onBlur}
                                        className="full-width"
                                    >{generateNumberOptions(0, 23)}</Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item {...restField} name={[name, 'to', 'min']}>
                                    <Select
                                        allowClear={true}
                                        showSearch={true}
                                        placeholder={'59'}
                                        optionFilterProp="children"
                                        filterOption={filterSelectOptionsByContent}
                                        getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                        onBlur={onBlur}
                                        className="full-width"
                                    >{generateNumberOptions(0, 59)}</Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ))}

                {!limit || fields.length < limit ?
                    <Button type="text" onClick={() => {
                        add()
                        onBlur()
                    }} icon={<PlusOutlined/>} className="padding-none-horizontal">
                        {l('add')}
                    </Button> : null
                }
            </>)}
        </Form.List>
    </>);
}

export const ProductsField = (
    {
        t,
        name = 'field',
        section = 'common',
        values = [],
        data = '',
        data_filters = {},
        onBlur = null,
        onDelete = null,
        container = 'app-page-wrapper', // for select field rendering. Use 'effects-list-wrapper' for graphs
    }
) => {
    const l = (c) => t('graph.container.form.products.' + c)

    // console.log('ProductsField values', values)

    return (<Form.List name={name}>
        {(fields, {add, remove}) => (<>
            {fields.map(({key, name, ...restField}) => (
                <div key={key} className="margin-bottom-sm">
                    <Row gutter={10} className="margin-bottom-xp">
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                name={[name, 'id']}
                                label={l('product.label') + ' #' + (key + 1)}
                            >
                                <Select
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={l('product.placeholder')}
                                    optionFilterProp="children"
                                    dropdownRender={(select_items) => {
                                        return <DropdownItemCreator
                                            t={t}
                                            items={select_items}
                                            table={data}
                                            fields={data_filters}
                                        />
                                    }}
                                    filterOption={filterSelectOptionsByContent}
                                    getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                                    onBlur={onBlur}
                                >
                                    {
                                        data ?
                                            OptionsFromObject({fieldName: name, items: values}) :
                                            LocaledOptions({t, section: section, fieldName: name, items: values})
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{alignItems: 'end', display: 'flex', paddingBottom: 3}}>
                            <Button type="text" title={l('delete')} onClick={() => {
                                remove(name)
                                onDelete(name)
                            }} icon={<DeleteOutlined/>}/>
                        </Col>
                    </Row>
                    <Row gutter={10} className="margin-bottom-xp">
                        <Col span={12}>
                            <Form.Item {...restField} name={[name, 'price']}>
                                <Input placeholder={l('price.placeholder')} onBlur={onBlur}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...restField} name={[name, 'quantity']}>
                                <Input placeholder={l('quantity.placeholder')} onBlur={onBlur}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ))}
            <Button type="text" onClick={() => {
                add()
                onBlur()
            }} icon={<PlusOutlined/>} className="padding-none-horizontal">
                {l('add')}
            </Button>
        </>)}
    </Form.List>);
}

export const TextAreaField = (
    {
        t,
        name = 'field',
        section = 'common',
        label = null,
        placeholder = null,
        required = false,
        max_length = 0,
        bordered = true,
        rules = null,
        onBlur = () => {
        },
        onChange = () => {
        },
        onKeyDown = null,
    }
) => {
    const rulesResult = required ? formRequiredRules : rules;

    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, label, 'label')}
            className="margin-none"
            name={getName(name)}
            rules={rulesResult}
        >
            <TextArea
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                showCount={!!max_length}
                maxLength={max_length ? max_length : null}
                autoSize={{
                    minRows: 1,
                    maxRows: 20,
                }}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={onKeyDown ? onKeyDown : fieldsHotkeys}
                bordered={bordered}
            />
        </Form.Item>
    );
}

export const CodeEditorField = (
    {
        t,
        form,
        formValues = null,
        name = 'field',
        section = 'common',
        label = null,
        language = 'javascript',
        placeholder = '{}',
        onBlur = () => {
        },
        onChange = () => {
        },
    }
) => {
    const fieldName = getName(name);
    let formValuesResult = formValues;
    if (!formValuesResult && form) formValuesResult = form.getFieldsValue();
    let fieldValue = deepGet(formValuesResult, fieldName);

    return (
        <Form.Item
            name={fieldName}
            label={SetFieldInfo(t, section, name, label, 'label')}
            // className="margin-none"
        >
            <CodeEditor
                form={form}
                fieldName={fieldName}
                initialValue={fieldValue}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                language={language}
            />
        </Form.Item>
    );
}

export const AIAssistantField = (
    {
        t,
        admin,
        project_id,
        name = 'field',
        section = 'common',
        label = null,
        fieldLabel = null,
        fieldDesc = null,
        placeholder = null,
        onFinish = null,
        prompt = null,
        className = 'btn-bordered',
        btnType = 'primary',
    }
) => {
    const [isVisibleModal, setVisibleModal] = useState(false);
    const [modalValue, setModalValue] = useState('');

    const buttonOnClick = () => {
        setVisibleModal(true);
    }

    const onOk = () => {
        askChatGpt(admin, project_id, modalValue, prompt).then(answer => {
            if (!deepGet(answer, ['ok'], false)) {
                notice.error(deepGet(answer, 'error.message', 'Unknown error'));
                return '';
            }

            let result = deepGet(answer, ['result', 'content'], '');

            onFinish(result);
            setVisibleModal(false);

        }).catch(e => {
            notice.error(deepGet(e.data, 'error.message', 'Unknown error'))
        })
    }


    return (
        <div className="ant-form-item">
            <Button onClick={buttonOnClick} type={btnType} className={className} icon={<ExperimentOutlined/>}>
                {SetFieldInfo(t, section, name, label, 'label')}
            </Button>

            <SimpleModal
                isVisible={isVisibleModal}
                setVisible={setVisibleModal}
                onOk={onOk}
                actionLabel="apply"
                title={SetFieldInfo(t, section, name, label, 'label')}
                width={798}
                loadingOnOk={true}
            >
                <NoForm>
                    <FormItemWrapper desc={SetFieldInfo(t, section, name, fieldDesc, 'fieldDesc')}>
                        <FormItemLabel label={SetFieldInfo(t, section, name, fieldLabel, 'fieldLabel')}/>
                        <TextArea
                            // className="margin-bottom-sm"
                            placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                            showCount={true}
                            maxLength={4000}
                            autoSize={{
                                minRows: 1,
                                maxRows: 20,
                            }}
                            // value={modalValue}
                            onChange={e => setModalValue(e.target.value)}
                            onKeyDown={fieldsHotkeys}
                        />
                    </FormItemWrapper>
                </NoForm>
            </SimpleModal>
        </div>
    );
}


export const EditorField = (
    {
        t,
        form,
        formValues = null,
        name = 'field',
        section = 'common',
        localeRoot = null,
        imgFormat = null,
        label = null,
        extended = true,
        onBlur = null,
        onChange = null,
    }
) => {
    let fieldValue = '';
    const fieldName = getName(name);

    if (!formValues && form) fieldValue = form.getFieldValue(fieldName);
    else fieldValue = deepGet(formValues, fieldName);

    // console.log('EditorField fieldValue', fieldValue, form && form.getFieldsValue(), formValues);
    // let itemId = parentId + '-' + name.split('.').join('-');

    return (
        <Form.Item
            name={fieldName}
            label={SetFieldInfo(t, localeRoot || section, name, label, 'label')}
            // className="margin-none"
        >
            <TinyMCE
                t={t}
                form={form}
                fieldName={fieldName}
                initialValue={fieldValue}
                onBlur={onBlur}
                onChange={onChange}
                section={section}
                extended={extended}
                imgFormat={imgFormat}
            />

        </Form.Item>
    );
}

export const MessageField = (props) => {
    return EditorField({...props, extended: false})
}

export const NumberField = (
    {
        t,
        label = null,
        name = 'field',
        section = 'common',
        required = false,
        defaultValue = undefined,
        size = null,
        step = 1,
        min = 0,
        max = null,
    }
) => {
    const rules = required ? formRequiredRules : null

    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={getName(name)}
            rules={rules}
        >
            <InputNumber
                min={min}
                max={max}
                size={size}
                step={step}
                decimalSeparator={t('common.decimal_separator')}
                defaultValue={defaultValue}
            />
        </Form.Item>
    );
}

export const HiddenField = (
    {
        name = 'field',
        required = false
    }
) => {
    const rules = required ? formRequiredRules : null

    return (
        <Form.Item
            className="hide"
            label={name}
            name={getName(name)}
            rules={rules}
        >
            <Input/>
        </Form.Item>
    );
}

export const StyleField = ({t, name = 'field', section = 'common'}) => {

    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, null, 'label')}
            name={getName(name)}
        >
            <Radio.Group buttonStyle="solid" className="buttons-colors">
                {['primary', 'default', 'success', 'danger'].map((button) => {
                    return <Radio.Button
                        key={'btn-' + button}
                        className={'btn-' + button}
                        value={button}>{t('common.form.style.' + button)}</Radio.Button>
                })}
            </Radio.Group>
        </Form.Item>
    )
}

export const MenuField = (
    {
        t,
        section = 'common',
        name = 'field',
        values = [],
        label = null,
        placeholder = null,
        required = false,
        localized = true,
        size = null,
        container = 'app-page-wrapper', // effects-list-wrapper
        features = [],
        data = '',
        data_filters = {},
        onChange = () => {
        },
        onBlur = () => {
        },
    }
) => {
    const rules = required ? formRequiredRules : null;
    let dropdownRender = null;
    let options = null;

    const optsFromObj = (values) => {
        options = [];
        for (const [key, value] of Object.entries(values)) {
            options.push({value: key, label: value})
        }
        return options;
    }

    if (data) {
        options = optsFromObj(values);
    } else if (localized) {
        options = values.map((item, index) => {
            return {value: item, label: t(`${section}.form.${name}.value.${item}`)}
        })
    } else if (Array.isArray(values)) {
        options = values.map((item) => {
            return {value: item, label: item}
        });
    } else if (typeof values === 'object') {
        options = optsFromObj(values);
    }

    if (data && inArray('creating', features)) {
        dropdownRender = (select_items) =>
            <DropdownItemCreator
                t={t}
                items={select_items}
                table={data}
                fields={data_filters}
            />
    }
    const placeholder_value = SetFieldInfo(t, section, name, placeholder, 'placeholder');

    return (
        <Form.Item
            className={size === 'small' ? 'ant-field-sm' : ''}
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={getName(name)}
            rules={rules}
        >
            <Select
                allowClear={!!placeholder_value}
                showSearch={!!data}
                placeholder={placeholder_value}
                optionFilterProp="children"
                dropdownRender={dropdownRender}
                filterOption={filterSelectOptionsByLabel}
                getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                onChange={onChange}
                onBlur={onBlur}
                size={size}
                options={options}
            />
        </Form.Item>
    );
}

export const TagsField = (
    {
        t,
        form,
        section = 'common',
        name = 'field',
        values = [],
        label = null,
        placeholder = null,
        required = false,
        disabled = false,
        localized = false,
        container = 'app-page-wrapper', // effects-list-wrapper
        features = [],
        data = '',
        size = null,
        data_filters = {},
        max = null,
        onChange = () => {
        },
        onBlur = () => {
        },
    }
) => {
    const fieldName = getName(name);
    const rules = required ? formRequiredRules : null

    let options = null;
    if (localized) {
        options = values.map((item) => {
            return {value: item, label: t(`${section}.form.${name}.value.${item}`)}
        })
    } else if (Array.isArray(values)) {
        options = values.map((item) => {
            return {value: item, label: item}
        });
    } else if (data || typeof values === 'object') {
        options = [];
        for (const [key, value] of Object.entries(values)) {
            options.push({value: key, label: value})
        }
    }

    let dropdownRender = null;
    if (data && inArray('creating', features)) {
        dropdownRender = (select_items) =>
            <DropdownItemCreator
                t={t}
                items={select_items}
                table={data}
                fields={data_filters}
            />
    }

    const editable = inArray('adding', features);

    const onTagChange = (value, label) => {
        if (!form) {
            console.info('The field set has no form passed!');
            return;
        }

        const fieldValues = form.getFieldValue(fieldName);
        const newValues = fieldValues.map(val => val === value ? label : val);
        form.setFieldValue(fieldName, newValues);
    }

    const tagRender = (props) => {
        const {label, value, closable, onClose, disabled, style, onChange} = props;
        // console.log('props', props)

        const handleInputChange = (e) => {
            onChange(value, e.target.textContent);
        };

        const handleInputClick = (e) => {
            // prevent items menu open
            e.stopPropagation();
            e.target.focus();
        };

        const handleKeyDown = (e) => {
            // Backspace key
            if (e.keyCode === 8) {
                e.stopPropagation();
                // remove antd hotkey
                if (e.target.textContent === '') {
                    const fieldValues = form.getFieldValue(fieldName);
                    form.setFieldValue(fieldName, fieldValues.filter(val => val !== value));

                    const instance = form.getFieldInstance(fieldName);
                    instance.focus();
                }
            }
        }

        return (
            <Tag
                closable={closable}
                onClose={onClose}
                disabled={disabled}
                size="medium"
                className="ant-select-selection-item tag-editable"
                style={style}
            >
                <div
                    className="editable ant-select-selection-item-content"
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onBlur={handleInputChange}
                    onClick={handleInputClick}
                    onMouseDown={handleInputClick}
                    onPaste={pasteOnlyText}
                    onKeyDown={handleKeyDown}
                >
                    {label}
                </div>
            </Tag>
        );
    };

    return (
        <Form.Item
            className={size === 'small' ? 'ant-field-sm' : ''}
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={fieldName}
            rules={rules}
        >
            <Select
                mode={inArray('adding', features) ? 'tags' : 'multiple'}
                optionFilterProp="children"
                dropdownRender={dropdownRender}
                getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                filterOption={filterSelectOptionsByLabel}
                onChange={onChange}
                onBlur={onBlur}
                maxTagCount={max ? max : null}
                size={size}
                disabled={disabled}
                tagRender={editable ? (props) => tagRender({...props, onChange: onTagChange}) : null}
                options={options}
            />
        </Form.Item>
    );
}

export const IconField = (
    {
        t,
        label = null,
        name = 'icon_name',
        onChange = null,
        placeholder = null,
        section = 'common',
        set = 'regular',
        container = 'app-page-wrapper', // effects-list-wrapper
    }
) => {
    const placeholder_value = SetFieldInfo(t, section, name, placeholder, 'placeholder');

    let itemLabel;
    if (label === true) itemLabel = t('common.form.icon.label')
    else itemLabel = SetFieldInfo(t, section, name, label, 'label');

    return (
        <Form.Item label={itemLabel} name={getName(name)}>
            <Select
                showSearch
                allowClear={!!placeholder_value}
                onChange={onChange}
                placeholder={t('common.form.icon.placeholder')}
                optionFilterProp="children"
                getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                filterOption={(input, option) =>
                    option.value.indexOf(input) >= 0
                }
            >{IconsOptions(set)}</Select>
        </Form.Item>
    );
}


export const TwColorField = (
    {
        t,
        name = 'color',
        label = null,
        onChange = null,
        placeholder = null,
        section = 'common',
        container = 'app-page-wrapper', // effects-list-wrapper
    }
) => {
    const placeholder_value = SetFieldInfo(t, section, name, placeholder, 'placeholder');

    const filterColor = (input, option, children = 1) => {
        const innerText = option.children[children].props.children.join('');
        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || innerText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    return (
        <Form.Item label={SetFieldInfo(t, section, name, label, 'label')} name={getName(name)}>
            <Select
                showSearch
                className="tw"
                allowClear={!!placeholder_value}
                onChange={onChange}
                placeholder={t('common.form.color.placeholder')}
                getPopupContainer={() => container.includes('.') ? document.querySelector(container) : document.getElementById(container)}
                optionFilterProp="children"
                filterOption={filterColor}
                // filterOption={(input, option) => option.value.indexOf(input) >= 0}
            >{ColorOptions(t)}</Select>
        </Form.Item>
    );
}

export const AvatarField = (
    {
        t,
        admin,
        project_id,
        form,
        section = 'common',
        localeRoot = null,
        label = '',
        placeholder = null,
        name = 'photo_url',
        photo_url = '',
        imageType = 'avatar',
        aspect = 1,
    }
) => {
    const fieldName = getName(name);
    // Upload field
    const [fileList, setFileList] = useState([]);

    const storeImageUrl = (image_url) => {
        // const formValues = form.getFieldsValue();
        // let newFormValues = deepSet(deepCopyObject(formValues), fieldName, image_url);
        form.setFieldValue(fieldName, image_url)
    }

    useEffect(() => {
        setFileList([]);
    }, [])

    useEffect(() => {
        let photoUrl = photo_url;

        // const fieldValue = form.getFieldValue(fieldName);
        // const fieldValue = deepGet(formValues, fieldName);
        // if (fieldValue) photoUrl = fieldValue;

        // console.log('AvatarField photo_url', photoUrl)

        if (photoUrl) {
            setFileList([{
                uid: '-1',
                name: 'avatar.jpg',
                status: 'done',
                url: photoUrl,
            }]);
        } else {
            setFileList([]);
        }
    }, [photo_url])

    // console.log('Render 1 - fileList', photo_url, fileList)

    return (<>
            <Form.Item className='hide' name={fieldName}>
                <Input placeholder={'https://cloud.example.com/image/file.jpg'}/>
            </Form.Item>

            {label !== '' ? <div className="ant-form-item">
                <div className="ant-form-item-label">
                    <label>{SetFieldInfo(t, localeRoot || section, name, label, 'label')}</label>
                </div>
            </div> : null}

            <AvatarUpload
                admin={admin}
                project_id={project_id}
                fileList={fileList}
                setFileList={setFileList}
                storeImageUrl={storeImageUrl}
                siteSection={section}
                imageType={imageType}
                buttonLabel={placeholder}
                aspect={aspect}
            />
        </>
    );
}

export const FolderField = ({t, section = 'common', folder_list = [], name = 'folder_id'}) => {
    return (
        <Form.Item label={t('common.form.folder.label')} name={getName(name)}>
            <Select
                placeholder={t('common.form.folder.placeholder')}
                dropdownRender={(select_items) =>
                    <DropdownItemCreator t={t} items={select_items} table="folder" fields={{section: section}}/>}
            >
                <Option value={0} key="folder-0">{t('common.form.folder.placeholder')}</Option>
                {folder_list ? folder_list.map((folder) => (
                    <Option value={folder.id} key={'folder-' + folder.id}>{folder.title}</Option>
                )) : null}
            </Select>
        </Form.Item>
    );
}

export const GameField = ({folder_list = '', name = 'folder_id'}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {admin, project} = useSelector(store => store);
    const [newItemTitle, setNewItemTitle] = useState('');

    const siteCreate = (title = 'New Site') => {
        const data = {
            project_id: project.item.id,
            title: title,
            description: 'Created automatically as folder',
            type: 'game',
        }
        dispatch({type: 'createSite', admin, data});
    }

    const onNameChange = (event) => {
        setNewItemTitle(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (newItemTitle) {
            siteCreate(newItemTitle);
            setNewItemTitle('');
        }
    };

    return (
        <Form.Item label={l('common.form.game.label')} name={getName(name)}>
            <Select
                placeholder="custom dropdown render"
                dropdownRender={(menuItems) => (<>
                    {menuItems}
                    <Divider style={{margin: '8px 0',}}/>
                    <Space style={{padding: '0 8px 4px',}}>
                        <Input
                            placeholder={t('game_folder.enter_new.placeholder')}
                            value={newItemTitle}
                            onChange={onNameChange}
                        />
                        <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                            {l('common.action.create')}
                        </Button>
                    </Space>
                </>)}
            >
                <Option value={0} key="folder-0">{l('common.form.game.placeholder')}</Option>
                {folder_list.map((folder) => (
                    <Option value={folder.id} key={'game-' + folder.id}>{folder.title}</Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export const SwitchField = (
    {
        t,
        label = null,
        section = 'common',
        name = 'is_on',
        valuePropName = 'checked',
        className = 'allow-overflow switcher-field',
        checked = false,
        onChange = () => {
        },
        onClick = () => {
        },
    }
) => {

    return (
        <Form.Item
            name={getName(name)}
            valuePropName={valuePropName}
            className={className}
        >
            <LabelSwitch
                checked={checked}
                onClick={onClick}
                onChange={onChange}
                label={SetFieldInfo(t, section, name, label, 'label')}
            />
        </Form.Item>
    );
}

export const CheckboxField = (
    {
        t,
        section = 'common',
        name = 'is_allowed',
        valuePropName = 'checked',
        className = 'allow-overflow',
        tooltip = true,
        label = null,
        desc = null,
        platforms = [],
        onChange = () => {
        },
        onClick = () => {
        },
    }
) => {
    return (
        <Tooltip title={SetFieldInfo(t, section, name, desc, 'desc')} placement={isMobile ? "top" : "left"}
                 overlayStyle={{display: tooltip ? '' : 'none'}}>
            <Form.Item name={getName(name)} valuePropName={valuePropName} className={className}>
                <Checkbox className={'fix-checkbox-align'} onClick={onClick} onChange={onChange}>
                    {SetFieldInfo(t, section, name, label, 'label')}
                    <LabelPlatformsIcons platforms={platforms}/>
                    {/*<InfoCircleOutlined className='hide'/> /!*funny crutch*!/*/}
                </Checkbox>
            </Form.Item>
        </Tooltip>
    )
}

export const CheckboxButton = (
    {
        name = 'options',
        value = false,
        valuePropName = 'checked',
        size = 'large',
        onChange = () => {
        },
        onClick = () => {
        },
    }
) => {
    const refCheckbox = useRef();
    return (
        <>
            <Form.Item name={getName(name)} valuePropName={valuePropName} className='hide'>
                <Checkbox ref={refCheckbox} onChange={onChange}/>
            </Form.Item>
            <Button
                className={`${classes.wrapper_icon}${value ? ' active' : ''}`}
                type="text"
                icon={<AiOutlineSetting/>}
                size={size}
                data-option='true'
                onClick={() => {
                    refCheckbox.current.input.click();
                    onClick();
                }}>
                {l('common.button.options')}
            </Button>
        </>
    )
}

export const DateTimeField = (
    {
        t,
        name = 'field',
        section = 'common',
        format = moment_formats.datetime,
        label = null,
        placeholder = null,
        required = false,
        className = null,
        bordered = true,
        disabled = false,
        disabledDate = null,
        disabledTime = null,
        onChange = () => {
        },
        onBlur = () => {
        }
    }
) => {
    const rules = required ? formRequiredRules : null;

    return (
        <Form.Item
            label={SetFieldInfo(t, section, name, label, 'label')}
            name={getName(name)}
            rules={rules}
            className={className}
        >
            <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                format={format}
                bordered={bordered}
                showTime={true}
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
                style={{width: '100%'}}
                minuteStep={5}
                secondStep={5}
                disabled={disabled}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
            />
        </Form.Item>
    );
}

export const DateRangeField = (
    {
        t,
        label = null,
        name = 'field',
        section = 'common',
        format = "D MMM HH:mm",
        required = false,
    }
) => {
    const rules = required ? formRequiredRules : null;
    const showTimeFrom = {defaultValue: moment('00:00:00', 'HH:mm:ss')}
    const showTimeBefore = {defaultValue: moment('23:59:59', 'HH:mm:ss')}

    return <>
        <FormItemLabel label={SetFieldInfo(t, section, name + '_at', label, 'label')}/>
        <div className="form-input-group">
            <Form.Item name={name + '_since'} rules={rules}>
                <DatePicker
                    showTime={showTimeFrom}
                    format={format}
                    placeholder={t('common.from')}
                />

            </Form.Item>
            <Form.Item name={name + '_until'} rules={rules}>
                <DatePicker
                    showTime={showTimeBefore}
                    format={format}
                    placeholder={t('common.before')}
                />
            </Form.Item>
        </div>
    </>
}


export const DateField = (
    {
        t,
        name = 'field',
        section = 'common',
        format = moment_formats.date,
        label = null,
        placeholder = null,
        required = false,
        onChange = () => {
        },
        onBlur = () => {
        }
    }
) => {
    return DateTimeField({t, name, section, format, label, placeholder, required, onBlur, onChange});
}

export const TimeField = (
    {
        t,
        name = 'TimePicker',
        section = 'common',
        format = moment_formats.time,
        label = null,
        placeholder = null,
        required = false,
        onChange = () => {
        },
        onBlur = () => {
        }
    }
) => {

    const rules = required ? formRequiredRules : null;
    return (
        <Form.Item label={SetFieldInfo(t, section, name, label, 'label')} name={getName(name)} rules={rules}>
            <TimePicker
                format={format}
                minuteStep={5}
                secondStep={5}
                onChange={onChange}
                onBlur={onBlur}
                style={{width: '100%'}}
                placeholder={SetFieldInfo(t, section, name, placeholder, 'placeholder')}
            />
        </Form.Item>
    );
}

export const DropdownItemCreator = (
    {
        t,
        items = [],
        table = 'folder',
        fields = {},
    }
) => {
    const dispatch = useDispatch();
    const {admin, project} = useSelector(store => store);
    const [newFolderTitle, setNewFolderTitle] = useState('');

    const itemCreate = (title = 'New Item') => {
        let data = {
            project_id: project.item.id,
            title: title,
        }
        if (objectLength(fields)) data = {...data, ...fields};
        if (table === 'field') data['icon_name'] = 'user-plus';
        if (table === 'glob') data['name'] = title;
        dispatch({type: 'create' + ucFirst(table), admin, data});
    }

    const onTitleChange = (event) => {
        setNewFolderTitle(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (newFolderTitle) {
            itemCreate(newFolderTitle);
            setNewFolderTitle('');
        }
    };

    return (<>
            {items}
            <Divider style={{margin: '8px 0'}}/>
            <Space style={{padding: '0 8px 4px'}}>
                <Input
                    placeholder={t('common.form.enter_new.placeholder')}
                    value={newFolderTitle}
                    onChange={onTitleChange}
                />
                <Button type="text" icon={<CheckOutlined/>} onClick={addItem} style={{padding: "4px 5px"}}>
                    {l('common.action.create')}
                </Button>
            </Space>
        </>
    );
}







